html {
	font-family: $font-family-r;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	font-size: $fs-small;
}

body {
	margin: 0;
	padding: 0;
	line-height: 1.5;
	font-size: $fs-small;
	font-family: $font-family-r;
}

article,
aside,
footer,
header,
nav,
section {
	display: block;
}


figcaption,
figure,
main {
	display: block;
}

figure {
	margin: 1em 0;
}

hr {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

pre {
	font-family: monospace, monospace;
	font-size: 1em;
}

a {
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
	outline-width: 0;
}

abbr[title] {
	border-bottom: 1px #767676 dotted;
	text-decoration: none;
}

b,
strong {
	font-weight: inherit;
}

b,
strong {
	font-weight: 700;
}

code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

dfn {
	font-style: italic;
}

mark {
	background-color: #eee;
	color: #222;
}

small {
	font-size: 80%;
}

sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

audio,
video {
	display: inline-block;
}

audio:not([controls]) {
	display: none;
	height: 0;
}

img {
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
	font-family: $font-family-r;
	font-size: 100%;
	margin: 0;
	outline: none;
}

button,
input {
	overflow: visible;
	outline: none;
}

button,
select {
	text-transform: none;
	outline: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	border-style: none;
	padding: 0;
	outline: none;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
	outline: 1px dotted ButtonText;
	outline: none;
}

fieldset {
	border: 1px solid #bbb;
	margin: 0 2px;
	padding: 0.35em 0.625em 0.75em;
}

legend {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	color: inherit;
	display: table;
	max-width: 100%;
	padding: 0;
	white-space: normal;
}

progress {
	display: inline-block;
	vertical-align: baseline;
}

textarea {
	overflow: auto;
}

[type="checkbox"],
[type="radio"] {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
	height: auto;
	outline: none;
}

[type="search"] {
	-webkit-appearance: textfield;
	outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
	outline: none
}

::-webkit-file-upload-button {
	-webkit-appearance: button;
	font: inherit;
	outline: none;
}

details,
menu {
	display: block;
}

summary {
	display: list-item;
}

canvas {
	display: inline-block;
}

template {
	display: none;
}

[hidden] {
	display: none;
}

/*--------------------------------------------------------------
2.0 Accessibility
--------------------------------------------------------------*/

/* Text meant only for screen readers. */

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	height: 1px;
	overflow: hidden;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	-webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 700;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
	outline: none;
}

/*--------------------------------------------------------------
3.0 Alignments
--------------------------------------------------------------*/

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/*--------------------------------------------------------------
4.0 Clearings
--------------------------------------------------------------*/

.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after,
.nav-links:before,
.nav-links:after,
.pagination:before,
.pagination:after,
.comment-author:before,
.comment-author:after,
.widget-area:before,
.widget-area:after,
.widget:before,
.widget:after,
.comment-meta:before,
.comment-meta:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after,
.nav-links:after,
.pagination:after,
.comment-author:after,
.widget-area:after,
.widget:after,
.comment-meta:after {
	clear: both;
}

/*--------------------------------------------------------------
5.0 Typography
--------------------------------------------------------------*/

body,
button,
input,
select,
textarea {
	color: $desc-color;
	font-family: $font-family-r;
	font-size: 1rem;
	font-weight: 400;
	outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	clear: both;
	margin: 0.25em 0 0.25em;
	font-weight: 600;
	color: $title-color;
	font-family: $font-family-f;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
	padding-top: 0;
}

h1 {
	font-size: 42px;
}

h2{
	font-size: 30px;
}

h3 {
	font-size: 20px;
}

h4 {
	font-size: 20px;
}

h5 {
 	font-size: 18px;
}

h6 {
	font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
    color: $title-color;
    line-height: 1.5;
	font-family: $font-family-f;
}
h2>span {
	font-weight: 700;
	color: $theme-color;
}
h5 {
	font-weight: 400;
	font-family: $font-family-f;
}
h5>span {
	font-weight: 600;
}
h1>a, h2>a, h3>a, h4>a, h5>a, h6>a {
    color: $title-color;
}
p {
	margin: 0 0 1.5em;
	padding: 0;
	font-size: 1em;
	color: $desc-color;
	font-family: $font-family-r;
	line-height: 26px;
}
dfn,
cite,
em,
i {
	font-style: italic;
}

blockquote {
    padding: 50px 0px 0px 0px;
    margin: 20px 0;
    font-weight: 400;
    color: $white-color;
    position: relative;
    font-style: italic;
    @include breakpoint(sm) {
    	padding: 0px 0px 0px 55px;
    }
    &::before {
		@extend %position;
		left: 0;
		top: 7px;
		background: url(../../assets/images/icon/bc-icon.png);
	    width: 40px;
	    height: 40px;
	    background-repeat: no-repeat;
	}
    p {
    	font-family: $font-family-f;
    	font-size: 17px;
    	line-height: 30px;
    	color: $title-color;
    	margin-bottom: 0;
    }
}
blockquote cite {
	display: block;
	font-style: normal;
	font-weight: 400;
	margin-top: 0.5em;
	font-size: $fs-base;
	color: $desc-color;
}

address {
	margin: 0 0 1.5em;
	line-height: 1.5;
}

pre {
	background: #eee;
	font-family: "Courier 10 Pitch", Courier, monospace;
	font-size: 1rem;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}

code,
kbd,
tt,
var {
	font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
	font-size: 1rem;
}

abbr,
acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark,
ins {
	background: #eee;
	text-decoration: none;
}

big {
	font-size: 125%;
}


:focus {
	outline: none;
}

/* Typography for Arabic Font */

html[lang="ar"] body,
html[lang="ar"] button,
html[lang="ar"] input,
html[lang="ar"] select,
html[lang="ar"] textarea,
html[lang="ary"] body,
html[lang="ary"] button,
html[lang="ary"] input,
html[lang="ary"] select,
html[lang="ary"] textarea,
html[lang="azb"] body,
html[lang="azb"] button,
html[lang="azb"] input,
html[lang="azb"] select,
html[lang="azb"] textarea,
html[lang="fa-IR"] body,
html[lang="fa-IR"] button,
html[lang="fa-IR"] input,
html[lang="fa-IR"] select,
html[lang="fa-IR"] textarea,
html[lang="haz"] body,
html[lang="haz"] button,
html[lang="haz"] input,
html[lang="haz"] select,
html[lang="haz"] textarea,
html[lang="ps"] body,
html[lang="ps"] button,
html[lang="ps"] input,
html[lang="ps"] select,
html[lang="ps"] textarea,
html[lang="ur"] body,
html[lang="ur"] button,
html[lang="ur"] input,
html[lang="ur"] select,
html[lang="ur"] textarea {
	font-family: Tahoma, Arial, sans-serif;
	outline: none;
}

html[lang="ar"] h1,
html[lang="ar"] h2,
html[lang="ar"] h3,
html[lang="ar"] h4,
html[lang="ar"] h5,
html[lang="ar"] h6,
html[lang="ary"] h1,
html[lang="ary"] h2,
html[lang="ary"] h3,
html[lang="ary"] h4,
html[lang="ary"] h5,
html[lang="ary"] h6,
html[lang="azb"] h1,
html[lang="azb"] h2,
html[lang="azb"] h3,
html[lang="azb"] h4,
html[lang="azb"] h5,
html[lang="azb"] h6,
html[lang="fa-IR"] h1,
html[lang="fa-IR"] h2,
html[lang="fa-IR"] h3,
html[lang="fa-IR"] h4,
html[lang="fa-IR"] h5,
html[lang="fa-IR"] h6,
html[lang="haz"] h1,
html[lang="haz"] h2,
html[lang="haz"] h3,
html[lang="haz"] h4,
html[lang="haz"] h5,
html[lang="haz"] h6,
html[lang="ps"] h1,
html[lang="ps"] h2,
html[lang="ps"] h3,
html[lang="ps"] h4,
html[lang="ps"] h5,
html[lang="ps"] h6,
html[lang="ur"] h1,
html[lang="ur"] h2,
html[lang="ur"] h3,
html[lang="ur"] h4,
html[lang="ur"] h5,
html[lang="ur"] h6 {
	font-weight: 700;
}

/* Typography for Chinese Font */

html[lang^="zh-"] body,
html[lang^="zh-"] button,
html[lang^="zh-"] input,
html[lang^="zh-"] select,
html[lang^="zh-"] textarea {
	font-family: "PingFang TC", "Helvetica Neue", Helvetica, STHeitiTC-Light, Arial, sans-serif;
}

html[lang="zh-CN"] body,
html[lang="zh-CN"] button,
html[lang="zh-CN"] input,
html[lang="zh-CN"] select,
html[lang="zh-CN"] textarea {
	font-family: "PingFang SC", "Helvetica Neue", Helvetica, STHeitiSC-Light, Arial, sans-serif;
}

html[lang^="zh-"] h1,
html[lang^="zh-"] h2,
html[lang^="zh-"] h3,
html[lang^="zh-"] h4,
html[lang^="zh-"] h5,
html[lang^="zh-"] h6 {
	font-weight: 700;
}

/* Typography for Cyrillic Font */

html[lang="bg-BG"] body,
html[lang="bg-BG"] button,
html[lang="bg-BG"] input,
html[lang="bg-BG"] select,
html[lang="bg-BG"] textarea,
html[lang="ru-RU"] body,
html[lang="ru-RU"] button,
html[lang="ru-RU"] input,
html[lang="ru-RU"] select,
html[lang="ru-RU"] textarea,
html[lang="uk"] body,
html[lang="uk"] button,
html[lang="uk"] input,
html[lang="uk"] select,
html[lang="uk"] textarea {
	font-family: "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
}

html[lang="bg-BG"] h1,
html[lang="bg-BG"] h2,
html[lang="bg-BG"] h3,
html[lang="bg-BG"] h4,
html[lang="bg-BG"] h5,
html[lang="bg-BG"] h6,
html[lang="ru-RU"] h1,
html[lang="ru-RU"] h2,
html[lang="ru-RU"] h3,
html[lang="ru-RU"] h4,
html[lang="ru-RU"] h5,
html[lang="ru-RU"] h6,
html[lang="uk"] h1,
html[lang="uk"] h2,
html[lang="uk"] h3,
html[lang="uk"] h4,
html[lang="uk"] h5,
html[lang="uk"] h6 {
	font-weight: 700;
}

/* Typography for Devanagari Font */

html[lang="bn-BD"] body,
html[lang="bn-BD"] button,
html[lang="bn-BD"] input,
html[lang="bn-BD"] select,
html[lang="bn-BD"] textarea,
html[lang="hi-IN"] body,
html[lang="hi-IN"] button,
html[lang="hi-IN"] input,
html[lang="hi-IN"] select,
html[lang="hi-IN"] textarea,
html[lang="mr-IN"] body,
html[lang="mr-IN"] button,
html[lang="mr-IN"] input,
html[lang="mr-IN"] select,
html[lang="mr-IN"] textarea {
	font-family: Arial, sans-serif;
}

html[lang="bn-BD"] h1,
html[lang="bn-BD"] h2,
html[lang="bn-BD"] h3,
html[lang="bn-BD"] h4,
html[lang="bn-BD"] h5,
html[lang="bn-BD"] h6,
html[lang="hi-IN"] h1,
html[lang="hi-IN"] h2,
html[lang="hi-IN"] h3,
html[lang="hi-IN"] h4,
html[lang="hi-IN"] h5,
html[lang="hi-IN"] h6,
html[lang="mr-IN"] h1,
html[lang="mr-IN"] h2,
html[lang="mr-IN"] h3,
html[lang="mr-IN"] h4,
html[lang="mr-IN"] h5,
html[lang="mr-IN"] h6 {
	font-weight: 700;
}

/* Typography for Greek Font */

html[lang="el"] body,
html[lang="el"] button,
html[lang="el"] input,
html[lang="el"] select,
html[lang="el"] textarea {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html[lang="el"] h1,
html[lang="el"] h2,
html[lang="el"] h3,
html[lang="el"] h4,
html[lang="el"] h5,
html[lang="el"] h6 {
	font-weight: 700;
}

/* Typography for Gujarati Font */

html[lang="gu-IN"] body,
html[lang="gu-IN"] button,
html[lang="gu-IN"] input,
html[lang="gu-IN"] select,
html[lang="gu-IN"] textarea {
	font-family: Arial, sans-serif;
}

html[lang="gu-IN"] h1,
html[lang="gu-IN"] h2,
html[lang="gu-IN"] h3,
html[lang="gu-IN"] h4,
html[lang="gu-IN"] h5,
html[lang="gu-IN"] h6 {
	font-weight: 700;
}

/* Typography for Hebrew Font */

html[lang="he-IL"] body,
html[lang="he-IL"] button,
html[lang="he-IL"] input,
html[lang="he-IL"] select,
html[lang="he-IL"] textarea {
	font-family: "Arial Hebrew", Arial, sans-serif;
}

html[lang="he-IL"] h1,
html[lang="he-IL"] h2,
html[lang="he-IL"] h3,
html[lang="he-IL"] h4,
html[lang="he-IL"] h5,
html[lang="he-IL"] h6 {
	font-weight: 700;
}

/* Typography for Japanese Font */

html[lang="ja"] body,
html[lang="ja"] button,
html[lang="ja"] input,
html[lang="ja"] select,
html[lang="ja"] textarea {
	font-family: "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
}

html[lang="ja"] h1,
html[lang="ja"] h2,
html[lang="ja"] h3,
html[lang="ja"] h4,
html[lang="ja"] h5,
html[lang="ja"] h6 {
	font-weight: 700;
}

/* Typography for Korean font */

html[lang="ko-KR"] body,
html[lang="ko-KR"] button,
html[lang="ko-KR"] input,
html[lang="ko-KR"] select,
html[lang="ko-KR"] textarea {
	font-family: "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", Dotum, sans-serif;
}

html[lang="ko-KR"] h1,
html[lang="ko-KR"] h2,
html[lang="ko-KR"] h3,
html[lang="ko-KR"] h4,
html[lang="ko-KR"] h5,
html[lang="ko-KR"] h6 {
	font-weight: 600;
}

/* Typography for Thai Font */

html[lang="th"] h1,
html[lang="th"] h2,
html[lang="th"] h3,
html[lang="th"] h4,
html[lang="th"] h5,
html[lang="th"] h6 {
	font-family: "Sukhumvit Set", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html[lang="th"] body,
html[lang="th"] button,
html[lang="th"] input,
html[lang="th"] select,
html[lang="th"] textarea {
	font-family: "Sukhumvit Set", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Remove letter-spacing for all non-latin alphabets */

html[lang="ar"] *,
html[lang="ary"] *,
html[lang="azb"] *,
html[lang="haz"] *,
html[lang="ps"] *,
html[lang^="zh-"] *,
html[lang="bg-BG"] *,
html[lang="ru-RU"] *,
html[lang="uk"] *,
html[lang="bn-BD"] *,
html[lang="hi-IN"] *,
html[lang="mr-IN"] *,
html[lang="el"] *,
html[lang="gu-IN"] *,
html[lang="he-IL"] *,
html[lang="ja"] *,
html[lang="ko-KR"] *,
html[lang="th"] * {
	letter-spacing: 0 !important;
}

/*--------------------------------------------------------------
6.0 Forms
--------------------------------------------------------------*/

label {
	color: $desc-color;
	display: block;
	font-weight: 800;
	margin-bottom: 0.5em;
}

fieldset {
	margin-bottom: 1em;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: #666;
	background: #fff;
	background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
	border: 1px solid $border-color;
	display: block;
	padding: 14px 20px;
	width: 100%;
	margin-bottom: 20px;
	outline: none;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
	color: #222;
	border-color: $desc-color;
	outline: none;
}

select {
    width: 100%;
    padding: 15px;
    padding-left: 20px;
    border: 1px solid #ededed;
    border-radius: 50px;
    margin-bottom: 1.25rem;
    -webkit-appearance: none;
}

input[type="radio"],
input[type="checkbox"] {
	margin-right: 0.5em;
}

input[type="radio"] + label,
input[type="checkbox"] + label {
	font-weight: 400;
}

button,
input[type="button"],
input[type="submit"] {}

input + button,
input + input[type="button"],
input + input[type="submit"] {
	padding: 0.75em 2em;
}

button.secondary,
input[type="reset"],
input[type="button"].secondary,
input[type="reset"].secondary,
input[type="submit"].secondary {
	background-color: #ddd;
	color: #222;
}

button:hover,
button:focus,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"]:hover,
input[type="submit"]:focus {
	background: #767676;
	outline: none;
}

button.secondary:hover,
button.secondary:focus,
input[type="reset"]:hover,
input[type="reset"]:focus,
input[type="button"].secondary:hover,
input[type="button"].secondary:focus,
input[type="reset"].secondary:hover,
input[type="reset"].secondary:focus,
input[type="submit"].secondary:hover,
input[type="submit"].secondary:focus {
	background: #bbb;
	outline: none;
}

.comment-form {
	display: flex;
	flex-wrap: wrap;
    margin-top: 40px;
	input[type="text"] {
    	padding: 15px;
		padding-left: 20px;
    	border: 1px solid $border-color;
    	margin-bottom: 30px;
	}
	input[type="email"] {
		padding: 15px;
		padding-left: 20px;
		border: 1px solid $border-color;
    	border-radius: 50px;
	}
	input[type="url"] {
		padding: 15px;
		padding-left: 20px;
		border: 0;
    	background: $ash-color;
	}
	input[type="submit"] {
		background: $primary-color;
	    margin-top: 10px;
	    color: $white-color;
		@include transition($transition);
	    cursor: pointer;
	}
	textarea {
		margin-bottom: 30px;
		border: 1px solid $border-color;
    	padding: 20px;
    	background: #f1f6fa;
	}
}


/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
	color: $desc-color;
	font-family: $font-family-r;
}

:-moz-placeholder {
	color: $desc-color;
}

::-moz-placeholder {
	color: $desc-color;
	opacity: 1;
	/* Since FF19 lowers the opacity of the placeholder by default */
}

::-ms-input-placeholder {
	color: $desc-color;
}

/*--------------------------------------------------------------
7.0 Formatting
--------------------------------------------------------------*/

hr {
	background-color: #bbb;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

/*--------------------------------------------------------------
8.0 Lists
--------------------------------------------------------------*/

ul,
ol {
	padding: 0;
}

ul {
	list-style: disc;
	margin-left: 1.5em;
}

ol {
	list-style: decimal;
	margin-left: 1.5em;
}

li > ul,
li > ol {
	margin-left: 1.5em;
}

dt {
	font-weight: 700;
	line-height: 1.5;   
	margin: 0 1.5em;
}

dd {
	margin: 0 1.5em 1.5em;
	line-height: 1.5;
}
li {
	list-style: none;
}
a {
	outline: none;
	&:hover {
		text-decoration: none;
	}
}
/*--------------------------------------------------------------
9.0 Tables
--------------------------------------------------------------*/

table {
	border-collapse: collapse;
	margin: 0 0 1.5em;
	width: 100%;
}

thead th {
	padding-bottom: 0.5em;
}

th {
	padding: 0.4em;
	text-align: left;
	border: 1px solid $border-color;
}

tr {
	border-bottom: 1px solid $border-color;
	border-top: 1px solid $border-color;
	border-left: 1px solid $border-color;
}

td {
	padding: 1em;
	border: 1px solid $border-color;
}
.breadcrumb {
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    background-color: transparent;
    border-radius: 0;
    margin: 0;
    display: flex;
}


th:last-child,
td:last-child {
	padding-right: 0;
}

/*--------------------------------------------------------------
10.0 Links
--------------------------------------------------------------*/

a {
	color: $desc-color;
	text-decoration: none;
	display: inline-block;
	outline: none;
}

a:focus {
	outline: none;
}

a:hover,
a:active {
	color: #000;
	outline: 0;
}

/* Hover effects */

.entry-summary a,
.widget a,
.site-footer .widget-area a,
.posts-navigation a,
.widget_authors a strong {
	-webkit-box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
	box-shadow: inset 0 -1px 0 rgba(15, 15, 15, 1);
	-webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
}

.entry-title a,
.entry-meta a,
.page-links a,
.page-links a .page-number,
.edit-link a,
.post-navigation a,
.logged-in-as a,
.comment-navigation a,
.comment-reply-link,
a .nav-title,
.site-info a,
.widget .widget-title a,
.widget ul li a,
.site-footer .widget-area ul li a,
.site-footer .widget-area ul li a {
	-webkit-box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
	box-shadow: inset 0 -1px 0 rgba(255, 255, 255, 1);
	text-decoration: none;
	-webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
	transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
}

.entry-summary a:focus,
.entry-summary a:hover,
.widget a:focus,
.widget a:hover,
.site-footer .widget-area a:focus,
.site-footer .widget-area a:hover,
.posts-navigation a:focus,
.posts-navigation a:hover,
.comment-reply-link:focus,
.comment-reply-link:hover,
.widget_authors a:focus strong,
.widget_authors a:hover strong,
.entry-title a:focus,
.entry-title a:hover,
.entry-meta a:focus,
.entry-meta a:hover,
.page-links a:focus .page-number,
.page-links a:hover .page-number,
.post-navigation a:focus,
.post-navigation a:hover,
.logged-in-as a:focus,
.logged-in-as a:hover,
a:focus .nav-title,
a:hover .nav-title,
.edit-link a:focus,
.edit-link a:hover,
.site-info a:focus,
.site-info a:hover,
.widget .widget-title a:focus,
.widget .widget-title a:hover,
.widget ul li a:focus,
.widget ul li a:hover {
	color: #000;
	outline: none;
}

/* Fixes linked images */
.entry-content a img,
.comment-content a img,
.widget a img {
	-webkit-box-shadow: 0 0 0 8px #fff;
	box-shadow: 0 0 0 8px #fff;
}

.post-navigation a:focus .icon,
.post-navigation a:hover .icon {
	color: #222;
}

/*--------------------------------------------------------------
11.0 Featured Image Hover
--------------------------------------------------------------*/

.post-thumbnail {
	margin-bottom: 1em;
}

.post-thumbnail a img {
	-webkit-backface-visibility: hidden;
	-webkit-transition: opacity 0.2s;
	transition: opacity 0.2s;
}

.post-thumbnail a:hover img,
.post-thumbnail a:focus img {
	opacity: 0.7;
	outline: none;
}


/*--------------------------------------------------------------
13.0 Layout
--------------------------------------------------------------*/

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

*,
*:before,
*:after {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}


#page {
	position: relative;
	word-wrap: break-word;
}

.wrap {
	margin-left: auto;
	margin-right: auto;
	max-width: 700px;
	padding-left: 2em;
	padding-right: 2em;
}

.wrap:after {
	clear: both;
	content: "";
	display: block;
}


/*--------------------------------------------------------------
13.4 Posts
--------------------------------------------------------------*/

/* Post Landing Page */

.sticky {
	position: relative;
}

.post:not(.sticky) .icon-thumb-tack {
	display: none;
}

.sticky .icon-thumb-tack {
	display: block;
	height: 18px;
	left: -1.5em;
	position: absolute;
	top: 1.65em;
	width: 20px;
}

.page .panel-content .entry-title,
.page-title,
body.page:not(.twentyseventeen-front-page) .entry-title {
	font-size: 1rem;
	font-weight: 400;
}

.entry-header .entry-title {
	margin-bottom: 0.25em;
}

.entry-title a {
	color: $desc-color;
	text-decoration: none;
	margin-left: -2px;
}

.entry-title:not(:first-child) {
	padding-top: 0;
}


.entry-meta a {
	color: #767676;
}

.byline,
.updated:not(.published) {
	// display: none;
}

.single .byline,
.group-blog .byline {
	display: inline;
}
.page-numbers {
	display: none;
	padding: 0.5em 0.75em;
}

.page-numbers.current {
	color: #767676;
	display: inline-block;
}

.page-numbers.current .screen-reader-text {
	clip: auto;
	height: auto;
	overflow: auto;
	position: relative !important;
	width: auto;
}

.prev.page-numbers,
.next.page-numbers {
	background-color: #ddd;
	-webkit-border-radius: 2px;
	border-radius: 2px;
	display: inline-block;
	padding: 0.25em 0.5em 0.4em;
}

.prev.page-numbers,
.next.page-numbers {
	-webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.prev.page-numbers:focus,
.prev.page-numbers:hover,
.next.page-numbers:focus,
.next.page-numbers:hover {
	background-color: #767676;
	color: #fff;
}

.prev.page-numbers {
	float: left;
}

.next.page-numbers {
	float: right;
}

/* Aligned blockquotes */

.entry-content blockquote.alignleft,
.entry-content blockquote.alignright {
	color: #666;
	font-size: 1rem;
	width: 48%;
}

/* Blog landing, search, archives */

.blog .site-main > article,
.archive .site-main > article,
.search .site-main > article {
	padding-bottom: 2em;
}

.blog .entry-meta a.post-edit-link,
.archive .entry-meta a.post-edit-link,
.search .entry-meta a.post-edit-link {
	color: #222;
	display: inline-block;
	margin-left: 1em;
	white-space: nowrap;
}

.search .page .entry-meta a.post-edit-link {
	margin-left: 0;
	white-space: nowrap;
}

.taxonomy-description {
	color: #666;
	font-size: 1rem;
}

/* More tag */

.entry-content .more-link:before {
	content: "";
	display: block;
	margin-top: 1.5em;
}

/* Single Post */


.single-featured-image-header {
	background-color: #fafafa;
	border-bottom: 1px solid #eee;
}

.single-featured-image-header img {
	display: block;
	margin: auto;
}

.page-links {
	font-size: 1rem;
	padding: 2em 0 3em;
}

.page-links .page-number {
	color: #767676;
	display: inline-block;
	padding: 0.5em 1em;
}

.page-links a {
	display: inline-block;
}

.page-links a .page-number {
	color: #222;
}

/* Post Formats */

.blog .format-status .entry-title,
.archive .format-status .entry-title,
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
	display: none;
}

.format-quote blockquote {
	overflow: visible;
	position: relative;
}

.format-quote blockquote .icon {
	display: block;
	height: 20px;
	left: -1.25em;
	position: absolute;
	top: 0.4em;
	-webkit-transform: scale(-1, 1);
	-ms-transform: scale(-1, 1);
	transform: scale(-1, 1);
	width: 20px;
}

/* Post Navigation */

.post-navigation {
	font-weight: 800;
	margin: 3em 0;
}

.post-navigation .nav-links {
	padding: 1em 0;
}

.nav-subtitle {
	background: transparent;
	display: block;
	margin-bottom: 1em;
	text-transform: uppercase;
}


.post-navigation .nav-next {
	margin-top: 1.5em;
}

.nav-links .nav-previous .nav-title .nav-title-icon-wrapper {
	margin-right: 0.5em;
}

.nav-links .nav-next .nav-title .nav-title-icon-wrapper {
	margin-left: 0.5em;
}

/*--------------------------------------------------------------
13.5 Pages
--------------------------------------------------------------*/



.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

.page:not(.home) #content {
	padding-bottom: 1.5em;
}

/* 404 page */

.error404 .page-content {
	padding-bottom: 4em;
}

.error404 .page-content .search-form,
.search .page-content .search-form {
	margin-bottom: 3em;
}

/*--------------------------------------------------------------
13.6 Footer
--------------------------------------------------------------*/

.site-footer {
	border-top: 1px solid #eee;
}

.site-footer .wrap {
	padding-bottom: 1.5em;
	padding-top: 2em;
}

/* Footer widgets */

.site-footer .widget-area {
	padding-bottom: 2em;
	padding-top: 2em;
}

/* Social nav */

.social-navigation {
	font-size: 1rem;
	margin-bottom: 1em;
}

.social-navigation ul {
	list-style: none;
	margin-bottom: 0;
	margin-left: 0;
}

.social-navigation li {
	display: inline;
}

.social-navigation a {
	background-color: #767676;
	-webkit-border-radius: 40px;
	border-radius: 40px;
	color: #fff;
	display: inline-block;
	height: 40px;
	margin: 0 1em 0.5em 0;
	text-align: center;
	width: 40px;
}

.social-navigation a:hover,
.social-navigation a:focus {
	background-color: $desc-color;
	outline: none;
}

.social-navigation .icon {
	height: 16px;
	top: 12px;
	width: 16px;
	vertical-align: top;
}

/* Site info */

.site-info {
	font-size: 1rem;
	margin-bottom: 1em;
}

.site-info a {
	color: #666;
}

.site-info .sep {
	margin: 0;
	display: block;
	visibility: hidden;
	height: 0;
	width: 100%;
}

.site-info span[role=separator] {
	padding: 0 0.2em 0 0.4em;
}

.site-info span[role=separator]::before {
	content: '\002f';
}

/*--------------------------------------------------------------
14.0 Comments
--------------------------------------------------------------*/

#comments {
	clear: both;
}

.comments-title {
	margin-bottom: 1.5em;
}

.comment-list,
.comment-list .children {
	list-style: none;
	margin: 0;
	padding: 0;
    margin-top: 20px;
}

.comment-list li:before {
	display: none;
}

.comment-author {
	font-size: 1rem;
	margin-bottom: 0.4em;
	position: relative;
	z-index: 2;
}

.comment-author .says {
	display: none;
}

.comment-meta {
	margin-bottom: 1rem;
}
.comment-list>.comment:first-child {
	border-top: none;
}
.comment {
    border-top: 1px solid $border-color;
    padding-top: 30px;
    &:first-child {
    	margin-top: 0;
    }
    .comment-body {
    	border-top: 1px solid $border-color;
    	&:first-child {
	    	border-top: none;
	    }
    }
}
.comment-metadata {
	font-size: $fs-small;
	text-transform: capitalize;
	font-weight: 400;
	width: 100%;
	@include breakpoint(lg) {
		padding-left: 20px;
	}
	.fn {
		display: flex;
	    justify-content: space-between;
    	align-items: center;
	    margin-bottom: 4px;
    	a {
			color: $desc-color;
		    font-family: $font-family-r;
		    outline: none;
		    display: block;
		    font-size: 1rem;
		    box-shadow: none;
		}
		.url {
			font-weight: 700;
		}
	}
	time {
	    font-weight: 400;
	    font-size: 0.875rem;
	    font-family: $font-family-r;
	    outline: none;
	}
}

.comment-metadata a.comment-edit-link {
	color: #222;
	margin-left: 1em;
}

.comment-body {
	color: $desc-color;
	font-size: 1rem;
}

.comment-reply-link {
	position: relative;
    text-transform: uppercase;
    color: $theme-color !important;
    font-weight: 400;
	@include transition($transition);
    &:hover {
    	color: $primary-color !important;
    }
}

.comment-reply-link .icon {
	color: #222;
	left: -2em;
	height: 1em;
	position: absolute;
	top: 0;
	width: 1em;
}

.no-comments,
.comment-awaiting-moderation {
	font-size: 1rem;
	font-style: italic;
}

.comments-pagination {
	margin: 2em 0 3em;
}

.form-submit {
	text-align: right;
}

.comment-form #wp-comment-cookies-consent {
	margin: 0 10px 0 0;
}

.comment-form .comment-form-cookies-consent label {
	display: inline;
}

/*--------------------------------------------------------------
15.0 Widgets
--------------------------------------------------------------*/

#secondary {
	padding: 1em 0 2em;
}

.widget {
	padding-bottom: 3em;
}

h2.widget-title {
	font-size: 1rem;
	margin-bottom: 1.5em;
}

.widget-title a {
	color: inherit;
}

/* widget forms */

.widget select {
	width: 100%;
}


/* widget lists */

.widget ul {
	list-style: none;
	margin: 0;
}
li {
	padding: 5px 0;
}
.widget ul li,
.widget ol li {
	border-bottom: 1px solid #ddd;
	border-top: 1px solid #ddd;
	padding: 0.5em 0;
}

.widget:not(.widget_tag_cloud) ul li + li {
	margin-top: -1px;
}

.widget ul li ul {
	margin: 0 0 -1px;
	padding: 0;
	position: relative;
}

.widget ul li li {
	border: 0;
	padding-left: 24px;
	padding-left: 1.5rem;
}

/* Widget lists of links */

.widget_top-posts ul li ul,
.widget_rss_links ul li ul,
.widget-grofile ul.grofile-links li ul,
.widget_pages ul li ul,
.widget_meta ul li ul {
	bottom: 0;
}

.widget_nav_menu ul li li,
.widget_top-posts ul li,
.widget_top-posts ul li li,
.widget_rss_links ul li,
.widget_rss_links ul li li,
.widget-grofile ul.grofile-links li,
.widget-grofile ul.grofile-links li li {
	padding-bottom: 0.25em;
	padding-top: 0.25em;
}

.widget_rss ul li {
	padding-bottom: 1em;
	padding-top: 1em;
}


/* Text widget */

.widget_text {
	word-wrap: break-word;
}

.widget_text ul {
	list-style: disc;
	margin: 0 0 1.5em 1.5em;
}

.widget_text ol {
	list-style: decimal;
}

.widget_text ul li,
.widget_text ol li {
	border: none;
}

.widget_text ul li:last-child,
.widget_text ol li:last-child {
	padding-bottom: 0;
}

.widget_text ul li ul {
	margin: 0 0 0 1.5em;
}

.widget_text ul li li {
	padding-left: 0;
	padding-right: 0;
}

.widget_text ol li {
	list-style-position: inside;
}

.widget_text ol li + li {
	margin-top: -1px;
}

/* RSS Widget */

.widget_rss .widget-title .rsswidget:first-child {
	float: right;
}

.widget_rss .widget-title .rsswidget:first-child:hover {
	background-color: transparent;
}

.widget_rss .widget-title .rsswidget:first-child img {
	display: block;
}

.widget_rss ul li {
	padding: 2.125em 0;
}

.widget_rss ul li:first-child {
	border-top: none;
	padding-top: 0;
}

.widget_rss li .rsswidget {
	font-size: 22px;
	font-size: 1.375rem;
	font-weight: 300;
}

.widget_rss .rss-date,
.widget_rss li cite {
	display: block;
	font-size: 10px;
	font-style: normal;
	font-weight: 800;
	letter-spacing: 0.18em;
	text-transform: uppercase;
}

.widget_rss .rss-date {
	margin: 0.5em 0 1.5em;
	padding: 0;
}

.widget_rss .rssSummary {
	margin-bottom: 0.5em;
}

/* Contact Info Widget */

.widget_contact_info .contact-map {
	margin-bottom: 0.5em;
}

/* Gravatar */

.widget-grofile h4 {
	font-size: 1rem;
	margin-bottom: 0;
}

/* Recent Comments */

.widget_recent_comments table,
.widget_recent_comments th,
.widget_recent_comments td {
	border: 0;
}

/* Recent Posts widget */

.widget_recent_entries .post-date {
	display: block;
}

/* Search */

.search-form {
	position: relative;
}

.search-form .search-submit {
	bottom: 3px;
	padding: 0.5em 1em;
	position: absolute;
	right: 3px;
	top: 3px;
}

.search-form .search-submit .icon {
	height: 24px;
	top: -2px;
	width: 24px;
}

/* Tag cloud widget */

.tagcloud ul li {
	float: left;
	border-top: 0;
	border-bottom: 0;
	padding: 0;
	margin: 4px 4px 0 0;
}


.widget .tagcloud a,
.widget.widget_tag_cloud a,
.wp_widget_tag_cloud a {
	border: 1px solid #ddd;
	-webkit-box-shadow: none;
	box-shadow: none;
	display: block;
	padding: 4px 10px 5px;
	position: relative;
	-webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
	width: auto;
	word-wrap: break-word;
	z-index: 0;
}

.widget .tagcloud a:hover,
.widget .tagcloud a:focus,
.widget.widget_tag_cloud a:hover,
.widget.widget_tag_cloud a:focus,
.wp_widget_tag_cloud a:hover,
.wp_widget_tag_cloud a:focus {
	border-color: #bbb;
	-webkit-box-shadow: none;
	box-shadow: none;
	text-decoration: none;
	outline: none;
}

/* Calendar widget */

.widget_calendar th,
.widget_calendar td {
	text-align: center;
}

.widget_calendar tfoot td {
	border: 0;
}

/* Gallery widget */

.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
	display: none;
}

/*--------------------------------------------------------------
16.0 Media
--------------------------------------------------------------*/

img,
video {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */
}

img.alignleft,
img.alignright {
	float: none;
	margin: 0;
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */

embed,
iframe,
object {
	margin-bottom: 1.5em;
	max-width: 100%;
}

/* Remove bottom on embeds that wrapped in paragraphs via wpautop. */

p > embed:only-child,
p > iframe:only-child,
p > object:only-child {
	margin-bottom: 0;
}

.wp-caption,
.gallery-caption {
	font-size: 1rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

/* Media Elements */

.mejs-container {
	margin-bottom: 1.5em;
}

/* Audio Player */

.mejs-controls a.mejs-horizontal-volume-slider,
.mejs-controls a.mejs-horizontal-volume-slider:focus,
.mejs-controls a.mejs-horizontal-volume-slider:hover {
	background: transparent;
	border: 0;
	outline: none;
}

/* Playlist Color Overrides: Light */

.site-content .wp-playlist-light {
	border-color: #eee;
	color: #222;
}

.site-content .wp-playlist-light .wp-playlist-current-item .wp-playlist-item-album {
	color: $desc-color;
}

.site-content .wp-playlist-light .wp-playlist-current-item .wp-playlist-item-artist {
	color: #767676;
}

.site-content .wp-playlist-light .wp-playlist-item {
	border-bottom: 1px dotted #eee;
	-webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.site-content .wp-playlist-light .wp-playlist-item:hover,
.site-content .wp-playlist-light .wp-playlist-item:focus {
	border-bottom-color: rgba(0, 0, 0, 0);
	background-color: #767676;
	color: #fff;
	outline: none;
}

.site-content .wp-playlist-light a.wp-playlist-caption:hover,
.site-content .wp-playlist-light .wp-playlist-item:hover a,
.site-content .wp-playlist-light .wp-playlist-item:focus a {
	color: #fff;
	outline: none;
}

/* Playlist Color Overrides: Dark */

.site-content .wp-playlist-dark {
	background: #222;
	border-color: $desc-color;
}

.site-content .wp-playlist-dark .mejs-container .mejs-controls {
	background-color: $desc-color;
}

.site-content .wp-playlist-dark .wp-playlist-caption {
	color: #fff;
}

.site-content .wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-album {
	color: #eee;
}

.site-content .wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-artist {
	color: #aaa;
}

.site-content .wp-playlist-dark .wp-playlist-playing {
	background-color: $desc-color;
}

.site-content .wp-playlist-dark .wp-playlist-item {
	border-bottom: 1px dotted #555;
	-webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
	transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.site-content .wp-playlist-dark .wp-playlist-item:hover,
.site-content .wp-playlist-dark .wp-playlist-item:focus {
	border-bottom-color: rgba(0, 0, 0, 0);
	background-color: #aaa;
	color: #222;
	outline: none;
}

.site-content .wp-playlist-dark a.wp-playlist-caption:hover,
.site-content .wp-playlist-dark .wp-playlist-item:hover a,
.site-content .wp-playlist-dark .wp-playlist-item:focus a {
	color: #222;
	outline: none;
}

/* Playlist Style Overrides */

.site-content .wp-playlist {
	padding: 0.625em 0.625em 0.3125em;
}

.site-content .wp-playlist-current-item .wp-playlist-item-title {
	font-weight: 700;
}

.site-content .wp-playlist-current-item .wp-playlist-item-album {
	font-style: normal;
}

.site-content .wp-playlist-current-item .wp-playlist-item-artist {
	font-size: 10px;
	font-weight: 800;
	letter-spacing: 0.1818em;
	text-transform: uppercase;
}

.site-content .wp-playlist-item {
	padding: 0 0.3125em;
	cursor: pointer;
}

.site-content .wp-playlist-item:last-of-type {
	border-bottom: none;
}

.site-content .wp-playlist-item a {
	padding: 0.3125em 0;
	border-bottom: none;
}

.site-content .wp-playlist-item a,
.site-content .wp-playlist-item a:focus,
.site-content .wp-playlist-item a:hover {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: transparent;
	outline: none;
}

.site-content .wp-playlist-item-length {
	top: 5px;
}

/* SVG Icons base styles */

.icon {
	display: inline-block;
	fill: currentColor;
	height: 1em;
	position: relative; /* Align more nicely with capital letters */
	top: -0.0625em;
	vertical-align: middle;
	width: 1em;
}

/*--------------------------------------------------------------
16.1 Galleries
--------------------------------------------------------------*/

.gallery-item {
	display: inline-block;
	text-align: left;
	vertical-align: top;
	margin: 0 0 1.5em;
	padding: 0 1em 0 0;
	width: 50%;
}

.gallery-columns-1 .gallery-item {
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
	background: none;
	display: inline-block;
	max-width: 100%;
	outline: none;
}

.gallery-item a img {
	display: block;
	-webkit-transition: -webkit-filter 0.2s ease-in;
	transition: -webkit-filter 0.2s ease-in;
	transition: filter 0.2s ease-in;
	transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
	-webkit-filter: opacity(60%);
	filter: opacity(60%);
	outline: none;
}

.gallery-caption {
	display: block;
	text-align: left;
	padding: 0 10px 0 0;
	margin-bottom: 0;
}



/*--------------------------------------------------------------
19.0 Media Queries
--------------------------------------------------------------*/

/* Adjust positioning of edit shortcuts, override style in customize-preview.css */

@media screen and (min-width: 576px) {
	/* Typography */
	body,
	button,
	input,
	select,
	textarea {
		font-size: 1rem;
		outline: none;
	}
	h1 {
	    font-size: $fs-h1;
	}
	h2 {
	    font-size: $fs-h2;
	}
	h3 {
	    font-size: $fs-h3;
	}
	h4 {
	    font-size: $fs-h4;
	}
	h5 {
	    font-size: $fs-h5;
	}
	h6 {
	    font-size: $fs-h6;
	}

	.entry-content blockquote.alignleft,
	.entry-content blockquote.alignright {
		font-size: 14px;
		font-size: 0.875rem;
	}

	/* Fix image alignment */
	img.alignleft {
		float: left;
		margin-right: 1.5em;
	}

	img.alignright {
		float: right;
		margin-left: 1.5em;
	}

	/* Site Branding */

	.site-branding {
		padding: 3em 0;
	}

	/* Front Page */

	.panel-content .wrap {
		padding-bottom: 2em;
		padding-top: 3.5em;
	}

	.page-one-column .panel-content .wrap {
		max-width: 740px;
	}

	.panel-content .entry-header {
		margin-bottom: 4.5em;
	}

	.panel-content .recent-posts .entry-header {
		margin-bottom: 0;
	}

	/* Blog Index, Archive, Search */

	.taxonomy-description {
		font-size: 14px;
		font-size: 0.875rem;
	}

	.page-numbers.current {
		font-size: 16px;
		font-size: 1rem;
	}

	/* Site Footer */

	.site-footer {
		font-size: 16px;
		font-size: 1rem;
	}

	/* Gallery Columns */

	.gallery-item {
		max-width: 25%;
	}

	.gallery-columns-1 .gallery-item {
		max-width: 100%;
	}

	.gallery-columns-2 .gallery-item {
		max-width: 50%;
	}

	.gallery-columns-3 .gallery-item {
		max-width: 33.33%;
	}

	.gallery-columns-4 .gallery-item {
		max-width: 25%;
	}
}

@media screen and (min-width: 768px) {

	.entry-content blockquote.alignleft,
	.entry-content blockquote.alignright {
		font-size: 13px;
		font-size: 0.8125rem;
	}

	/* Layout */

	.wrap {
		max-width: 1000px;
		padding-left: 3em;
		padding-right: 3em;
	}	

	.comments-pagination,
	.post-navigation {
		clear: both;
	}

	.post-navigation .nav-previous {
		float: left;
		width: 50%;
	}

	.post-navigation .nav-next {
		float: right;
		text-align: right;
		width: 50%;
	}

	.nav-next,
	.post-navigation .nav-next {
		margin-top: 0;
	}

	/* Blog, archive, search */

	.sticky .icon-thumb-tack {
		height: 23px;
		left: -2.5em;
		top: 1.5em;
		width: 32px;
	}

	

	.navigation.pagination {
		clear: both;
		float: right;
		width: 58%;
	}


	/* Entry content */

	/* without sidebar */

	:not(.has-sidebar) .entry-content blockquote.alignleft {
		margin-left: -17.5%;
		width: 48%;
	}

	:not(.has-sidebar) .entry-content blockquote.alignright {
		margin-right: -17.5%;
		width: 48%;
	}

	/* with sidebar */

	.has-sidebar .entry-content blockquote.alignleft {
		margin-left: 0;
		width: 34%;
	}

	.has-sidebar .entry-content blockquote.alignright {
		margin-right: 0;
		width: 34%;
	}

	.has-sidebar #primary .entry-content blockquote.alignright.below-entry-meta {
		margin-right: -72.5%;
		width: 62%;
	}


	/* Comments */

	#comments {
		padding: 25px 30px;
	}

	.comments-title {
		margin-bottom: 2.5em;
	}

	ol.children .children {
		padding-left: 3em;
	}

	/* Posts pagination */

	.nav-links .nav-title {
		position: relative;
	}

	.nav-title-icon-wrapper {
		position: absolute;
		text-align: center;
		width: 2em;
	}

	.nav-links .nav-previous .nav-title .nav-title-icon-wrapper {
		left: -2em;
	}

	.nav-links .nav-next .nav-title .nav-title-icon-wrapper {
		right: -2em;
	}

	/* Secondary */

	#secondary {
		font-size: 14px;
		font-size: 0.875rem;
	}

	/* Widgets */

	h2.widget-title {
		font-size: 11px;
		font-size: 0.6875rem;
		margin-bottom: 2em;
	}

	/* Footer */

	.site-footer {
		font-size: 14px;
		font-size: 0.875rem;
		margin-top: 3em;
	}

	.site-footer .widget-column.footer-widget-1 {
		float: left;
		width: 36%;
	}

	.site-footer .widget-column.footer-widget-2 {
		float: right;
		width: 58%;
	}

	.social-navigation {
		clear: left;
		float: left;
		margin-bottom: 0;
		width: 36%;
	}

	.site-info {
		float: left;
		padding: 0.7em 0 0;
		width: 58%;
	}

	.social-navigation + .site-info {
		margin-left: 6%;
	}

	.site-info .sep {
		margin: 0 0.5em;
		display: inline;
		visibility: visible;
		height: auto;
		width: auto;
	}

	/* Gallery Columns */

	.gallery-columns-5 .gallery-item {
		max-width: 20%;
	}

	.gallery-columns-6 .gallery-item {
		max-width: 16.66%;
	}

	.gallery-columns-7 .gallery-item {
		max-width: 14.28%;
	}

	.gallery-columns-8 .gallery-item {
		max-width: 12.5%;
	}

	.gallery-columns-9 .gallery-item {
		max-width: 11.11%;
	}
}

@media screen and ( min-width: 992px ) {

	/* Pagination */

	.page-numbers {
		display: inline-block;
	}

	.page-numbers.current {
		font-size: 15px;
		font-size: 0.9375rem;
	}

	.page-numbers.current .screen-reader-text {
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		overflow: hidden;
		position: absolute !important;
		width: 1px;
	}

	/* Comments */

	.comment-meta {
		display: flex;
	    align-items: center;
	}
}