.blog-page {
	.section-wrapper {
		@extend %flex;
		margin: 0;
		.blog {
			// margin-left: -30px;
			margin-right: -30px;
			margin-bottom: 30px;
			@include breakpoint(lg) {
				margin-bottom: 0;
				margin-right: -15px;
			}
			.section-wrapper {
				.post-item {
					width: 100%;
					&:first-child {
						.post-inner {
							margin-top: 0;
						}
					}
					&:last-child {
						.post-inner {
							margin-bottom: 0;
						}
					}
					.post-inner {
						margin: 15px 0;
						@include breakpoint(lg) {
							margin: 15px;
						}
					}
					.post-thumb {
						a {
							img {
								width: 100%;
							}
						}
					}
				}
			}
			&.blog-single {
				.section-wrapper {
					.post-item {
						.post-inner {
							&:hover {
								.post-thumb {
									img {
										transform: scale(1);
									}
								}
							}
							.post-content {
							    align-items: flex-start;
							    padding-bottom: 0;
								.content-left {}
								.content-part {
									padding: 0 20px;
									@include breakpoint(sm) {
										padding: 0 30px;
									}
									.entry-content {
										p {}
										blockquote {
											p {}
										}
										ul {
											@extend %mp-0;
											width: 100%;
											float: left;
											margin-bottom: 20px !important;
											@include breakpoint(md) {
												width: 50%;
												margin-bottom: 0;
											}
											li {
												position: relative;
												padding-left: 20px;
												font-family: $font-family-f;
												color: $title-color;
												&::before {
													@extend %position;
													top: 50%;
													left: 0;
													width: 10px;
													height: 10px;
													border-radius: 50px;
													border: 1px solid $primary-color;
													transform: translateY(-50%);
												}
											}
										}
										img {
											width: 100%;
											float: left;
											margin-bottom: 15px;
											@include breakpoint(md) {
												width: 50%;
											}
										}
									}
								}
								.tags-section {
									width: 100%;
								    @extend %flex;
								    justify-content: center;
								    padding: 20px;
								    border-top: 1px solid #ededed;
								    @include breakpoint(sm) {
								    	padding: 20px 30px 20px 85px;
								    }
								    @include breakpoint(md) {
								    	justify-content: space-between;
								    }
									.tags {
										@extend %flex;
										@extend %mp-0;
									    align-items: center;
										li {
											padding: 0;
											a {
												display: inline-block;
												outline: none;
												padding: 10px;
												margin: 3px;
												border: 1px solid $border-color;
												color: $title-color;
												text-decoration: none;
												@include transition($transition);
											    border-radius: 3px;
											    @include breakpoint(sm) {
											    	margin: 0 3px;
											    }
											    &:hover {
											    	background-image: linear-gradient(to left, #fed643, #ffb045, #ff8c53, #fb6c66, #ff4181);
													color: $white-color;
											    }
											}
										}
									}
									.social-link-list {
										@include breakpoint(md) {
											margin-top: 0;
										}
										li {
											padding: 0;
											a {
												color: $white-color;
												border-radius: 0;
												&:hover {
													i {
														color: $white-color !important;
													}
												}
												&.facebook {
													background: $facebook;
													&:hover {
														border-radius: 50px;
														i {
															color: $white-color;
														}
													}
												}
												&.twitter {
													background: $twitter;
													&:hover {
														border-radius: 50px;
														i {
															color: $white-color;
														}
													}
												}
												&.linkedin {
													background: $linkedin;
													&:hover {
														border-radius: 50px;
														i {
															color: $white-color;
														}
													}
												}
												&.google {
													background: $google;
													&:hover {
														border-radius: 50px;
														i {
															color: $white-color;
														}
													}
												}
												i {}
											}
										}
									}
								}
							}
						}
					}
					.comments {
						margin-top: 30px;
						background: $white-color;
						padding: 25px 20px;
						width: 100%;
						border: 10px solid #f1f6fa;
						@include breakpoint(sm) {
							padding: 25px 30px;
						}
						@include breakpoint(lg) {
							margin: 0 15px;
						}
						.title-border {
							font-size: 22px;
						  	color: #000f3a;
						  	margin: 0 0 28px 0;
						  	position: relative;
						  	&::before {
							    @extend %position;
							    width: 50px;
							    height: 5px;
							    background: #e54295;
							    bottom: -20px;
							    border-radius: 2px;
							}
							&::after {
							    @extend %position;
							    border-bottom: 5px dotted #e54295;
							    width: 36px;
							    bottom: -20px;
							    left: 55px;
							}
						}
						.comment-list {
							.comment {
								.com-thumb {
									height: 50px;
									width: 50px;
									border-radius: 100%;
									overflow: hidden;
									margin-right: 15px;
									float: left;
									@include breakpoint(sm) {}
									@include breakpoint(md) {
										height: 80px;
    									width: 80px;
									}
									img {}
								}
								.com-content {
									overflow: hidden;
								  	padding-bottom: 20px;
								  	border-bottom: 1px solid #dbdbdb;
								  	&:last-child {
								  		border-bottom: none;
								  	}
									.com-title {
										@include breakpoint(sm) {
											display: flex;
											flex-wrap: wrap;
											justify-content: space-between;
											align-items: center;
										}
										.com-title-meta {
											h4 {
												line-height: 1;
												margin: 0;
												a {
													color: $title-color;
													@include transition($transition);
													&:hover {
														color: $theme-color;
													}
												}
											}
											span {
												font-size: $fs-h6;
												color: $desc-color;
												font-family: $font-family-f;
												display: inline-block;
												margin-bottom: 5px;
											}
										}
										.reply {
											@include breakpoint(max-sm) {
												margin-bottom: 10px;
												display: inline-block;
											}
											.comment-reply-link {
												i {}
											}
										}
									}
									p {
										margin-bottom: 0;
									}
								}
								.comment-list {
									@include breakpoint(sm) {
										padding-left: 30px;
									}
									.comment {
										list-style: none;
									  	overflow: hidden;
									  	border-bottom: 1px solid #dbdbdb;
									  	padding: 20px 0px;
									  	&:last-child {
									  		border: none;
											padding-bottom: 0px;
									  	}
										.com-thumb {
											img {}
										}
										.com-content {
											.com-title {
												.com-title-meta {
													h4 {
														a {}
													}
													span {}
												}
												.reply {
													.comment-reply-link {
														i {}
													}
												}
											}
											p {}
										}
									}
								}
							}
						}
					}
					.comment-respond {
						background: $white-color;
						padding: 25px 20px 40px;
						margin-top: 30px;
						border: 10px solid #f1f6fa;
						@include breakpoint(sm) {
							padding: 25px 30px 40px;
						}
						@include breakpoint(lg) {
							margin: 30px 15px 0 15px;
						}
						.add-comment {
							.title-border {
								position: relative;
								margin-bottom: 50px;
								&::before {
								    @extend %position;
								    width: 50px;
								    height: 5px;
								    background-color: #e54295;
								    bottom: -20px;
								    border-radius: 2px;
								}
								&::after {
								    @extend %position;
								    border-bottom: 5px dotted #e54295;
								    width: 36px;
								    bottom: -20px;
								    left: 55px;
								}
							}
							.comment-form {
								justify-content: space-between;
								input[type="text"] {
									background: #f1f6fa;
									color: $desc-color;
									padding: 10px 15px;
									width: 100%;
									&:nth-child(1) {
										@include breakpoint(md) {
										  	width: 48%;
										  	margin-right: 13px;
										  }
									}
									&:nth-child(2) {
										@include breakpoint(md) {
									 		width: 48%;
									 	}
									}
								}
								button {
									border: none;
									cursor: pointer;
									background-image: linear-gradient(to left, #fed643, #ff4181);
									&.btn {
										padding: 16px 38px;
										&:hover {
											background-image: linear-gradient(to left, #ff4181, #fed643);
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.sidebar-widget {
			margin: 0 -15px;
			@include breakpoint(lg) {
				margin: 0;
				margin-right: -15px;
			}
			h4 {
				position: relative;
    			margin-bottom: 30px;
    			&::before {
    				@extend %position;
				    width: 50px;
				    height: 5px;
				    background-color: #e54295;
				    bottom: -20px;
				    border-radius: 2px;
				}
				&::after {
				    @extend %position;
				    border-bottom: 5px dotted #e54295;
				    width: 36px;
				    bottom: -20px;
				    left: 55px;
				}
			}
			.widget-search {
				@extend %border;
				@extend %widget-pad;
    			form {
    				position: relative;
    				input {
    					padding: 17px;
					  	background: #f7fbff;
					  	border: none;
					  	width: 100%;
					  	position: relative;
					  	outline: none;
					  	margin-bottom: 0;
    					margin-top: 20px;
    					display: inline-block;
    				}
    				button {
    					@extend %position;
					    right: 0px;
					    background: transparent;
					    color: #858585;
					    line-height: 1;
					    bottom: 0px;
					    border: none;
					    cursor: pointer;
					    width: 40px;
					    height: 53px;
					    padding: 0;
					    &:hover {
					    	background: #e8f0fe;
					    }
    				}
    			}
			}
			.post-catagori {
				@extend %border;
				@extend %widget-mt;
				@extend %widget-pad;
				ul {
					@extend %mp-0;
					li {
						width: 100%;
					    margin: 5px 0;
					    list-style: none;
					    overflow: hidden;
					    border-bottom: 1px solid #ededed;
					    &:hover {
					    	span {
					    		color: $theme-color;
					    	}
					    }
					    &:last-child {
					    	border-bottom: none;
					    }
						a {
							width: calc(100% - 50px);
						    float: left;
						    display: inline-block;
						    text-transform: capitalize;
						    padding: 10px 0;
						    padding-left: 15px;
						    position: relative;
						    color: $desc-color;
						    @include transition($transition);
						    &:hover {
						    	color: $theme-color;
    							padding-left: 25px;
    							&::before {
    								color: $theme-color;
    								padding-left: 10px;
    							}
						    }
						    &::before {
						    	position: absolute;
								content: "\eac7";
								font-family: IcoFont !important;
								font-weight: 600;
							    font-size: 12px;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
						   		@include transition($transition);
						    }
						}
						span {
							width: 50px;
						    float: right;
						    font-size: 18px;
						    color: $desc-color;
						    text-align: center;
						    padding: 4px 0px;
						    margin-top: 3px;
						}
					}
				}
			}
			.widget-rec-post {
				@extend %widget-pad;
				@extend %widget-mt;
				@extend %border;
				.recent-post {
					@extend %mp-0;
					li {
						@extend %flex;
						align-items: center;
					    justify-content: space-around;
					    padding: 15px 0;
					    list-style: none;
					    border-bottom: 1px solid $border-color;
					    &:last-child {
					    	border-bottom: none;
					    }
						.rec-content {
							width: calc(100% - 90px);
							padding-right: 10px;
							@include breakpoint(lg) {
								width: 70%;
							}
							h6 {
								a {
									@include transition($transition);
									&:hover {
										color: $theme-color;
									}
								}
							}
							span {}
						}
						.rec-thumb {
							width: 90px;
							text-align: right;
							@include breakpoint(lg) {
								width: 30%;
							}
							a {
								display: block;
								img {
									display: block;
								}
							}
						}
					}
				}
			}
			.widget-tags {
				@extend %border;
				@extend %widget-mt;
				@extend %widget-pad;
				ul {
					@extend %mp-0;
					@extend %flex;
					padding-top: 20px !important;
					li {
						padding: 0;
						margin: 2px;
						a {
							padding: 8px 11px;
							color: $desc-color;
							border-radius: 1px;
							border: 1px solid $border-color;
							@include transition($transition);
							&:hover {
								color: $white-color;
								background: linear-gradient(to left, #fed643, #ff4181);
							}
						}
					}
				}
			}
			.widget-instagram {
				@extend %border;
				@extend %widget-mt;
				@extend %widget-pad;
				ul {
					@extend %mp-0;
					@extend %flex;
					justify-content: center;
					padding-top: 20px !important;
					margin: -3px !important;
					li {
						padding: 0;
						margin: 3px;
						a {
							display: block;
							img {
								display: block;
							}
						}
					}
				}
			}
			.widget-add {
				@extend %border;
				@extend %widget-mt;
				@extend %widget-pad;
				a {
					padding-top: 20px;
					display: block;
					img {
						display: block;
						width: 100%;
					}
				}
			}
		}
	}
	&.leftSidebar {

	}
}

// pagination-area start here
.pagination-area {
	padding: 20px 0 0;
	.pagination {
		margin: 0;
	    align-items: center;
		li {
			list-style: none;
			&.dot {
				font-weight: 700;
			    padding: 10px;
				font-size: $fs-h6;
			}
			&.prev {
				margin-right: 5px;
				a {
					i {
						font-weight: 600;
					}
				}
			}
			&.next {
				margin-left: 5px;
				a {
					i {
						font-weight: 600;
					}
				}
			}
			a{
				width: 46px;
				height: 46px;
				line-height: 46px;
				text-align: center;
				background: $white-color;
				color: $title-color;
    			margin: 0 2px;
    			text-transform: capitalize;
    			font-weight: 600;
    			border: 1px solid $border-color;
    			border-radius: 50px;
    			display: inline-block;
    			@include transition($transition);
    			&:hover {
    				background-image: linear-gradient(to left, #fed643, #ff4181);
    				color: $white-color !important;
    			}
    			&.active {
    				background-image: linear-gradient(to left, #fed643, #ff4181);
    				color: $white-color !important;
    			}
    		}
		}
	}
}