// about 
.about {
	position: relative;
	overflow: hidden;
	.section-wrapper {
		position: relative;
		z-index: 1;
		@extend %flex;
		.title {
			max-width: 545px;
			h2 {
				font-weight: 300;
				margin-bottom: 15px;
				line-height: 40px;
				span {
					font-weight: 700;
				}
			}
			h3 {
				font-weight: 400;
				margin: 0;
			}
			p {}
		}
		.about-left {
			width: calc(100% / 1);
			@include breakpoint(lg) {
				width: calc(100% / 2 - 15px);
				margin-right: 15px;
			}
			.about-thumb {
				margin-bottom: 60px;
				@include breakpoint(lg) {
					margin-bottom: 0;
					margin-top: 40px;
				}
				img {}
			}
		}
		.about-right {
			width: calc(100% / 1);
			@include breakpoint(lg) {
				width: calc(100% / 2 - 15px);
				margin-left: 15px;
			}
			.about-item {
				.about-content {
					ul {
						@extend %mp-0;
						li {
							@extend %flex;
							.name {
								width: 130px;
								p {
									font-size: $fs-h6;
									margin-bottom: 0;
									font-family: $font-family-f;
								}
							}
							.info {
								width: calc(100% - 130px);
								font-weight: 400;
								p {
									font-size: $fs-h6;
									color: $title-color;
									position: relative;
									padding-left: 20px;
									margin-bottom: 0;
									&::after {
										position: absolute;
										content: ":";
										top: 50%;
										left: 0;
									    font-weight: 700;
									    color: #000;
										transform: translateY(-50%);
									}
								}
							}
						}
					}
				}
				.btn {
					margin-top: 40px;
				}
			}
		}
	}
}

// sponsor section
.sponsor {
	padding: 30px 0;
	.section-wrapper {
		.sponsor-slider {
			overflow: hidden;
			.swiper-wrapper {
				display: flex;
				align-items: baseline;
				.sponsor-item {
					text-align: center;
					cursor: all-scroll;
					&:hover {
						img {
							opacity: .5;
						}
					}
					img {
						@include transition($transition);
					}
				}
			}
		}
	}
}

// Experience section
.experience {
    background-repeat: no-repeat;
	background-position: bottom;
	.section-header {
		.title {
			h2 {
				span{}
			}
		}
	}
	.section-wrapper {
		@extend  %flex;
		.exp-item {
			width: 100%;
			@include breakpoint(lg) {
				width: calc(100% / 2);
			}
			&:nth-child(even) {
				.exp-inner {
					margin-right: 0;
				}
			}
			&:nth-child(odd) {
				.exp-inner {
					margin-left: 0;
				}
			}
			.exp-inner {
				margin: 15px 0;
				padding: 25px;
				border-radius: 1px;
				background: $white-color;
				box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
				@include transition($transition);
				@include breakpoint(lg) {
					padding: 34px;
				}
				@include breakpoint(lg) {
					margin: 15px;
				}
				&:hover {
					transform: translateY(-10px);
					.exp-content {
						h3 {
							color: $theme-color;
						}
					}
				}
				.exp-thumb {
					@extend %flex;
					position: relative;
					align-items: center;
					h6 {
						margin: 0;
						width: 100%;
						font-weight: 400;
						@include breakpoint(sm) {
							padding-left: 20px;
							width: auto;
						}
					}
					.exp-cata {
						position: absolute;
						top: 0;
						right: 0;
						@include breakpoint(sm) {
							top: 50%;
							transform: translateY(-50%);
						}
					    span {
					    	position: relative;
					    	font-size: 16px;
						    padding: 6px 14px;
						    background-image: linear-gradient(to right, #fed643, #ff4181);
						    color: $white-color;
						    border-radius: 1px;
						    &::before {
						    	position: absolute;
							    content: "";
							    width: 0;
							    height: 0;
							    left: -5px;
							    top: 50%;
							    transform: translateY(-50%) rotate(-45deg);
							    border-left: 0px solid transparent;
							    border-right: 10px solid transparent;
							    border-top: 10px solid $primary-color;
						    }
					    }
					}
				}
				.exp-content {
					h3 {
						@include transition($transition);
						margin-bottom: 10px;
					}
					p {
						line-height: 24px;
						margin: 0;
					}
				}
			}
		}
	}
	.text-center {
		margin-top: 40px;
	}
}

// services section
.services {
	.section-header {
		max-width: 690px;
	}
	.section-wrapper {
		@extend %flex;
		.service-item {
			width: 100%;
			@include breakpoint(lg) {
				width: calc(100% / 2);
			}
			&:nth-child(even) {
				.service-inner {
					margin-right: 0;
				}
			}
			&:nth-child(odd) {
				.service-inner {
					margin-left: 0;
				}
			}
			&:nth-child(2) {
				.service-inner {
					background-image: linear-gradient(to top, #645cff, #ff5b8a);
					.service-content {
						ul {
							li {
								&::before {
									border-color: #ff4181;
								}
							}
						}
					}
					.service-thumb {
					    animation: animate1 10s infinite linear;
					}
				}
			}
			&:nth-child(3) {
				.service-inner {
					background-image: linear-gradient(to top, #cc00ff, #8484fb);
					.service-content {
						ul {
							li {
								&::before {
									border-color: #e7b53f;
								}
							}
						}
					}
					.service-thumb {
					    animation: animate1 13s infinite linear;
					}
				}
			}
			&:nth-child(4) {
				.service-inner {
					background-image: linear-gradient(to top, #81dcfd, #f4d63d);
					.service-content {
						ul {
							li {
								&::before {
									border-color: #645cff;
								}
							}
						}
					}
					.service-thumb {
					    animation: animate1 15s infinite linear;
					}
				}
			}
			.service-inner {
				margin: 15px 0;
				border-radius: 1px;
				background-image: linear-gradient(to top, #00a9ce, #8af5d1);
				position: relative;
				align-items: center;
				box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1);
				@extend %flex;
				@include breakpoint(lg) {
					margin: 15px;
				}
				&::before {
					@include breakpoint(sm) {
						position: absolute;
						content: "";
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url(../../assets/images/service/service-bg.png);
						background-position: left bottom;
	    				background-repeat: no-repeat;
	    			}
				}
				.service-content {
					width: 100%;
					padding: 32px;
					padding-right: 0;
					position: relative;
					z-index: 1;
					@include breakpoint(sm) {
    					width: calc(100% / 2);
    				}
					h3 {
						margin: 0;
						margin-bottom: 5px;
					}
					p {
						margin-bottom: 10px;
					}
					ul {
						margin: 0;
						padding: 0;
						li {
							list-style: none;
							position: relative;
						    padding: 3px 0;
							padding-left: 20px;
							&::before {
								position: absolute;
								content: "";
								width: 9px;
								height: 9px;
								border-radius: 50%;
								border: 2px solid #80f0d1;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
							}
						}
					}
				}
				.service-thumb {
				    width: 100%;
    				position: relative;
    				padding-right: 10px;
    				text-align: center;
    				animation: animate1 8s infinite linear;
    				@include breakpoint(sm) {
    					width: calc(100% / 2);
    					text-align: right;
    				}
    				img {
    					float: none;

    					@include breakpoint(sm) {
	    					float: right;
	    				}
    				}
				}
			}
		}
	}
}
@media only screen and (max-width: 1199px) and (min-width: 992px) {
	.services {
		.section-wrapper {
			.service-item {
				.service-inner {
					&::before {
						display: none;
					}
					.service-content {
					    padding: 39px 0px 39px 25px;
						h3 {
							color: $white-color;
						}
						p {
							color: $white-color;
						}
						ul {
							li {
								color: $white-color;
								&::before {
									border-color: $white-color !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

@include breakpoint(max-sm) {
	.services {
		.section-wrapper {
			.service-item {
				.service-inner {
					&::before {
						display: none;
					}
					.service-content {
					    padding: 39px 0px 39px 25px;
						h3 {
							color: $white-color;
						}
						p {
							color: $white-color;
						}
						ul {
							li {
								color: $white-color;
								&::before {
									border-color: $white-color !important;
								}
							}
						}
					}
				}
			}
		}
	}
}

// portfolio section
.portfolio {
	.portfolio-top {
		@extend %flex;
	    align-items: center;
		.portfolio-left {
			width: 100%;
			@include breakpoint(lg) {
				width: calc(100% / 2);
			}
			.section-header {
				.title {
					h2 {
						span {}
					}
				}
			}
		}
		.portfolio-right {
			width: 100%;
			@include breakpoint(lg) {
				width: calc(100% / 2);
			}
			ul {
				@extend %flex;
				margin: 0;
				justify-content: center;
				margin-bottom: 30px;
				@include breakpoint(lg) {
					justify-content: flex-end;
					margin-bottom: 0;
				}
				@include breakpoint(xl) {
					margin-right: -15px;
				}
				li {
					padding: 11px 22px;
					margin-right: 10px;
					margin-bottom: 10px;
				    color: $title-color;
				    background: $f2-color;
				    font-family: $font-family-f;
				    font-size: $fs-h6;
				    cursor: pointer;
				    border-radius: 1px;
				    span {
				    	font-weight: 500;
				    	padding: 0 5px;
				    }
					&:last-child {
						@include breakpoint(xl) {
							margin-right: 0;
						}
					}
					&.active {
				    	color: $white-color;
				    	background-image: linear-gradient(to right, #fed643, #ff4181);
    					box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.1);
					}
				}
			}
		}
	}
	.section-wrapper {
		margin: 0 -7.5px;
		.grid {
			@extend %flex;
			@include breakpoint(md) {
				margin: 0 -7.5px;
			}
			.grid-item {
				width: 100%;
				@include breakpoint(md) {
					width: calc(100% / 2);
				}
				@include breakpoint(lg) {
					width: calc(100% / 3);
				}
				.grid-inner {
					margin: 7.5px;
					.thumb {
						position: relative;
						display: block;
						a {
							display: block;
							img {
								display: block;
							}
						}
						img {
							display: block;
							width: 100%;
						}
						.thumb-icon {
							@extend %position;
							top: 50%;
							left: 50%;
							transform: translateX(-50%) translateY(-50%);
							a {
								width: 60px;
								height: 60px;
								line-height: 60px;
								border-radius: 50%;
								background: $theme-color;
								color: $white-color;
								text-align: center;
								border: 3px solid #fff;
								@include transition($transition);
								&:hover {
									background: $white-color;
									color: $theme-color;
									border-color: $theme-color;
								}
								i {
									font-size: $fs-h4;
								}
							}
						}
					}
				}
			}
		}
	}
}

// pricing section
.pricing {
	background-repeat: no-repeat;
	background-position: left bottom;
	background-size: cover;
	.section-header {
		max-width: 500px;
		.title {
			h2 {
				span {}
			}
		}
	}
	.section-wrapper {
		@extend %flex;
		justify-content: center;
		.pricing-item {
			width: calc(100% / 1);
			@include breakpoint(md) {
				width: calc(100% / 2);
			}
			@include breakpoint(xl) {
				width: calc(100% / 3);
			}
			&:nth-child(1) {
				.pricing-inner {
					margin-left: 0;
				}
			}
			&:nth-child(2) {
				.pricing-inner {
					@include breakpoint(max-xl) {
						margin-right: 0;
					}
				}
			}
			&:nth-child(3) {
				.pricing-inner {
					@include breakpoint(xl) {
						margin-right: 0;
					}
				}
			}
			.pricing-inner {
				padding: 40px;
				margin: 15px 0;
				border-radius: 8px;
				background: $white-color;
				box-shadow: 0px 3px 15px 0px rgba(0,0,0,0.1);
				position: relative;
				@include transition($transition);
				@include breakpoint(md) {
					margin: 15px;
				}
				@include breakpoint(xl) {
					margin: 0 15px;
				}
				&::before {
					@extend %position;
					@extend %pricing-bt;
				    background-image: linear-gradient(to right, #ffda41, #ff4181);
				}
				&:hover {
					transform: translateY(-10px);
					.pricing-content {
						.pricing-header {
							h3 {
								color: $theme-color;
							}
						}
					}
				}
				.pricing-content {
					.pricing-header {
						h3 {
							@include transition($transition);
						}
						p {}
					}
					.pricing-body {
						.pricing-title {
							padding: 30px 0;
							padding-bottom: 15px;
							border-top: 1px solid $border-color;
							h5 {
								margin: 0;
								text-transform: capitalize;
							}
							h3 {
								font-size: 42px;
								line-height: 46px;
								color: $theme-color;
								margin: 0;
								font-weight: 700;
								span {
									font-size: $fs-h6;
									color: $desc-color;
									font-weight: 400;
								}
							}
							p {
								margin-bottom: 0;
							}
						}
					}
					.pricing-list {
						@extend %mp-0;
						margin-bottom: 30px !important;
						li {
							position: relative;
							padding-left: 25px;
							&::before {
								@extend %position;
								top: 50%;
								left: 0;
								transform: translateY(-50%);
								content: "\eed6" !important;
								font-family: IcoFont !important;
								color: $theme-color;
								font-size: $fs-h4;
							}
						}
					}
					.text-center {
						.btn {
							font-family: $font-family-f;
						}
					}
				}
			}
		}
	}
}

// testimonial section
.testimonial {
	position: relative;
	overflow: hidden;
	background-repeat: no-repeat;
	background-position: bottom;
	.section-header {
		max-width: 510px;
		position: relative;
		z-index: 1;
	}
	.section-wrapper {
		overflow: hidden;
		.testimonial-slider {
			padding: 10px;
			.testimonial-item {
				.testimonial-inner {
					background: $white-color;
					box-shadow: 0px 10px 35px 0px #f0edf37a;
					@include transition($transition);
					&:hover {
						box-shadow: 0px 3px 30px 0px rgba(0,0,0,0.1);
					}
					.testimonial-head {
						@extend %flex;
						padding: 20px;
						align-items: center;
						justify-content: space-between;
						border-bottom: 1px solid $border-color;
						.testi-top {
							@extend %flex;
						}
						.testimonial-thumb {
							width: 60px;
							height: 60px;
							border-radius: 50%;
							overflow: hidden;
							img {
								width: 100%;
								height: 100%;
							}
						}
						.name-des {
							padding-left: 10px;
							padding-right: 20px;
							h5 {
								@extend %mp-0;
								font-weight: 600;
							}
							h6 {
								@extend %mp-0;
								font-weight: 400;
								font-family: $font-family-r;
							}
						}
						img {}
					}
					.testimonial-body {
						padding: 20px;
						p {
							margin-bottom: 0;
						}
					}
					.testimonial-footer {
						@extend %flex;
						justify-content: space-between;
						align-items: center;
						padding: 0 20px;
						padding-bottom: 20px;
						ul {
							@extend %flex;
							@extend %mp-0;
							li {
								i {
									color: $primary-color;
								}
							}
						}
					}
				}
			}
		}
	}
}

// blog section
.blog  {
	.blog-header-part {
		@extend %flex;
		justify-content: center;
		align-items: center;
		@include breakpoint(md) {
			justify-content: space-between;
		}
		.section-header {
			max-width: 400px;
			@include breakpoint(max-sm) {
				margin-bottom: 20px !important;
			}
		}
		.btn {
			@include breakpoint(max-sm) {
				display: none;
			}
			@include breakpoint(xl) {
				margin-right: -15px;
			}
		}
	}
	.section-wrapper {
		@extend %flex;
		.post-item {
			width: calc(100% / 1);
			@include breakpoint(lg) {
				width: calc(100% / 2);
			}
			&:first-child {
				.post-inner {
					margin-left: 0;
				}
			}
			&:last-child {
				.post-inner {
					margin-right: 0;
				}
			}
			.post-inner {
				margin: 15px 0;
				background: #f4f9fd;
				@include breakpoint(lg) {
					margin: 0 15px;
				}
				&:hover {
					.post-thumb {
						a {
							img {
								transform: scale(1.03);
							}
						}
					}
					.post-content {
						.content-part {
							.entry-header {
								h3 {
									a {
										color: $theme-color;
									}
								}
							}
						}
					}
				}
				.post-thumb {
					overflow: hidden;
					a {
						display: block;
						img {
							display: block;
							width: 100%;
							@include transition($transition);
						}
					}
				}
				.post-content {
					@extend %flex;
					align-items: center;
					padding: 20px 0 35px;
				    margin: 10px;
				    margin-top: -30px;
				    background: $white-color;
				    z-index: 1;
				    position: relative;
			        transform: translateY(-10px);
			        @include breakpoint(sm) {
			        	padding: 35px 0;
			        }
					.content-left {
						width: 100%;
						@extend %mp-0;
					    text-align: left;
					    padding: 0 20px !important;
					    @extend %flex;
					    align-items: center;
					    margin-bottom: 20px !important;
					    padding-bottom: 15px !important;
						border-bottom: 1px solid $border-color;
    					@include breakpoint(sm) {
    						display: block !important;
							width: 60px;
					   		text-align: center;
					   		padding: 0 !important;
					    	margin-bottom: 0px !important;
							border-bottom: none;
    						border-right: 1px solid $border-color;
    					}
						li {
							position: relative;
							padding: 0;
							@include breakpoint(sm) {
								border-bottom: 1px solid $border-color;
							}
							&:last-child {
								border-bottom: none;
							}
							&:hover {
								.social-media-list {
									transform: scaleX(1);
									@include breakpoint(sm) {
										transform: scaleY(1);
									}
								}
							}
							a {
								padding: 0 20px;
								display: block;
								@include breakpoint(sm) {
									padding: 10px 0;
								}
								img {}
							}
							p {
								margin-bottom: 0;
								padding-bottom: 5px;
								text-align: center;
							}
							.social-media-list {
								@extend %position;
								@extend %mp-0;
								@extend %flex;
								@include transition($transition);
								transform: scaleX(0);
								width: 320px;
								z-index: 1;
								top: 0px;
								left: 100%;
								transform-origin: left;
								@include breakpoint(sm) {
									display: block !important;
									top: 100%;
									left: 0;
									width: 100%;
									transform-origin: top;
									transform: scaleY(0);
								}
								li {
									padding: 0;
									text-align: center;
									a {
										padding: 15px;
										display: block;
										@include transition($transition);
										i {
											font-size: $fs-h6;
											color: $white-color;
										}
									}
								}
							}
						}
					}
					.content-part {
						width: 100%;
						padding: 0 20px;
						@include breakpoint(sm) {
							width: calc(100% - 60px);
							padding: 0 30px;
						}
						.entry-header {
							.entry-meta {
								@extend %flex;
								font-size: $fs-h6;
								.byline {
									font-family: $font-family-f;
									margin-bottom: 0;
									margin-right: 10px;
									a {
										color: $theme-color;
										margin-left: 5px;
									}
								}
								.posted-on {
									font-family: $font-family-f;
									margin-bottom: 0;
									a {
										color: $theme-color;
										margin-left: 5px;
									}
								}
							}
							h3 {
								line-height: 30px;
								margin: 10px 0;
								a {
									@include transition($transition);
								}
							}
						}
						.entry-content {
							p {}
							.btn {}
						}
					}
				}
			}
		}
	}
}

// contact section
.contact {
	background-position: bottom;
	background-repeat: no-repeat;
	background-size: cover;
	.section-header {
		max-width: 710px;
		.title {
			h2 {}
		}
	}
	.section-wrapper {
		.maps {
			&#gmap {
				height: 450px;
			}
		}
		.banner-bottom {
			border-bottom: none;
			padding: 40px 0;
			.bottom-wrapper {
				@extend %mp-0;
			}
		}
		form {
			.form-group {
				input {
					border-color: $border-color;
				}
				textarea {
					border-color: $border-color;
					margin-bottom: 30px;
				}
				.btn {
					padding: 11px 39px;
					z-index: 1;
				}
			}
		}
	}
}
.maps {
	height: 450px;
	iframe {
		width: 100%;
		height: 100%;
	}
}