// mobile menu start here
.mobile-header {
    padding: 10px 25px;
    background: $white-color;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 111;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 10px -2px rgba(0,0,0,0.1);
    @include breakpoint(sm) {
        .header-logo {
            max-width: 100%;
        }
    }
    @include breakpoint(max-sm) {
        padding: 10px 15px;
    }
    .header-bar {
        position: relative;
        width: 25px;
        height: 25px;
        cursor: pointer;
        span{
            background: $theme-color;
            width: 100%;
            height: 3px;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            margin-top: -1px;
            transform-origin: center;
            @include transition($transition);
            &:nth-child(1) {
                margin-top: -9px;
            }
            &:nth-child(2) {
                opacity: 1;
            }
            &:nth-child(3) {
                margin-top: 7px;
            }
        }
        &.m-menu-bar {
            span:nth-child(1) {
                transform: rotate(-45deg);
                margin-top: -1px;
            }
            span:nth-child(2) {
                opacity: 0;
            }
            span:nth-child(3) {
                transform: rotate(45deg);
                margin-top: -1px;
            }
        }
    }
}

.mobile-menu-area {
    &.m-menu-zero {
        opacity: 1;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255,255,255,.5);
    }
    .mobile-menu-area-inner {
        height: 100vh;
        padding: 40px 20px;
        padding-left: 0;
        overflow-y: scroll;
        .header-bar {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: center;
            padding-bottom: 15px;
            position: relative;
        }
        .m-menu {
            text-align: center;
            margin: 0;
            li {
                list-style: none;
                padding: 0;
                position: relative;
                border-bottom: 1px solid #fafafa;
                &:last-child {
                    border-bottom: none;
                }
                a {
                    display: block !important;
                    padding: 10px 0;
                    text-transform: capitalize;
                    margin: 2px 0;
                    text-align: left;
                    outline: none;
                    color: $title-color;
                    font-family: $font-family-f;
                    &:hover {
                        color: $primary-color;
                    }
                }
                &:hover {
                    ul {
                        display: block;
                    }
                }
                ul {
                    display: none;
                    li {
                        a {
                            border-bottom: 1px solid $border-color;
                        }
                    }
                }
            }
        }
    }
}

nav.mobile-header.close {
    display: none;
}

/* Close Button */
.close-button {
    width: 36px;
    height: 36px;
    line-height: 36px;
    position: relative;
    right: 0;
    top: 0;
    overflow: hidden;
    text-indent: 16px;
    border: none;
    z-index: 1001;
    background: $theme-color;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
}
.close-button::before,
.close-button::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 18px;
    top: 50%;
    left: 50%;
    background: $white-color;
    transform: translate(-50%, -50%);
}
.close-button::before {
    -webkit-transform: translate(-50%, -50%) rotate(45deg);
    transform: translate(-50%, -50%) rotate(45deg);
}
.close-button::after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
}

/* Menu */
.menu-wrap {
    position: fixed;
    z-index: 1001;
    width: 390px;
    height: 100vh;
    top: 0;
    right: 0;
    font-size: 1.15em;
    -webkit-transform: translate3d(390px,0,0);
    transform: translate3d(390px,0,0);
    box-shadow: 0px 3px 10px 0px rgba(0,0,0,0.1,);
    @include transition($transition);
}

@media (max-width: 450px) {
    .menu-wrap {
        width: 320px;
        -webkit-transform: translate3d(320px,0,0);
        transform: translate3d(320px,0,0);
    }
}
.morph-shape svg {
    transform: rotate(180deg);
}
.menu {
    background: $white-color;
    width: calc(100% - 40px);
    height: 100%;
    float: right;
    right: 0;
    top: 0;
}

/* Morph Shape */
.morph-shape {
    position: absolute;
    width: 40px;
    height: 100%;
    top: 0;
    left: 0;
    fill: #fff;
    z-index: 1000;
    background: #fff;
}

/* Shown menu */
.show-menu .menu-wrap {
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
    right: 0;
}
