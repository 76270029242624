$bootstrap-sass-asset-helper: false !default;

//
// Variables
// --------------------------------------------------


// Colors
$title-color: #000;
$desc-color: #696969;
$theme-color: #ff4181;
$primary-color: #fed643;
$border-color: #ececec;
$white-color: #fff;
$ash-color: #f4f9fd;
$f2-color: #f2f2f2;

// social-media color
$facebook: #3b5998;
$twitter: #55acee;
$linkedin: #007bb5;
$pinterest: #007bb5;
$skype: #2fb7df;
$youtube: #cd201f;
$google: #dd4b39;
$camera: #e4405f;
$instagram: #e4405f;
$tumblr: #34465d;
$behance: #1769ff;
$star: #e9ba17;
$green: #0be881;
$green-h: #0fbcf9;
$red: #f53b57;
$yellow: #dec32b;
$nell: #212fb4;
$navy: #d8e0f3;
$plaseholder: #888;

//== Typography

// font-family

$font-family-f:  'Frank Ruhl Libre', serif;
$font-family-r:  'Roboto', sans-serif;

// font-size

$fs-base:   15px !default;
$fs-small: 	15px;
$fs-h1:		60px;
$fs-h2: 	36px;
$fs-h3: 	24px;
$fs-h4: 	20px;
$fs-h5: 	18px;
$fs-h6: 	16px;		

//  transition
$transition: all 0.3s ease;