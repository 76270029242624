.header-logo{
	display: inline-block;
	width: auto;
	a {
		display: inline-block;
		outline: none;
	}
}
.header {
	.primary-menu {
	    background: transparent;
        position: fixed;
    	width: 100%;
		z-index: 99;
		@include transition($transition);
		.menu-area {
			@include breakpoint(lg) {
				margin: 0 -15px;
			}
		}
	}
}
.main-menu {
	list-style: none;
	margin: 0;
    justify-content: center;
	width: auto;
	li {
		padding: 0;
		position: relative;
	    &.active {
	    	a {
		    	&::after {
		    		transform: scaleX(1);
		    	}
		    }
	    }
		a {
			color: $title-color;
			padding: 18px 10px;
			display: inline-block;
			margin: 0 5px;
			font-size: 17px;
			font-weight: 400;
			position: relative;
			text-decoration: none;
			font-family: $font-family-f;
			@include transition($transition);
		    &:hover {}
		}
		&:hover>{
			a {
				color: $theme-color;
			}
			ul {
				top: calc(100% + 0px);
				opacity: 1;
				visibility: visible;
			}
		}
		&:last-child {
			a {
				padding-right: 0;
			}
		}
		ul {
			position: absolute;
			top: calc(100% + 30px);
			left: 0;
			width: 270px;
			opacity: 0;
			background: $white-color;
			transition: all .3s ease;
			visibility: hidden;
			box-shadow: 0 3px 5px rgba(0, 0, 0, .2);
			li {
				a {
					display: block;
					border-bottom: 1px solid $border-color;
				}
			}
		}
	}
}
