// footer-section start here
.footer {
	padding: 85px 0;
    position: relative;
    z-index: 3;
    @include breakpoint(max-xl) {
		background-image: linear-gradient(to left, #fed643, #e15377);
    }
    &::after {
		@extend %position;
		width: 100%;
		height: 200%;
		top: -100%;
		right: 0;
		background-size: cover;
		background: url(../../assets/images/shape/pattern.png);
	}
	.text-center {
		position: relative;
		z-index: 9;
		.social-link-list {
			margin-top: 0;
			li {
				a {
					margin: 7px;
					font-size: $fs-h6;
					&:hover {
						transform: translateY(-5px);
					}
					i {
						&.icofont-rss {
						    transform: translateY(-2px) translateX(2px);
						    display: inline-block;
						}
					}
				}
			}
		}
		.footer-logo {
			padding: 10px 0;
		}
		p {
			margin-bottom: 0;
			color: $white-color;
			@include breakpoint(lg) {
				color: $title-color;
			}
			@include breakpoint(xl) {
				color: $white-color
			}
			a {
				color: $white-color;
				font-weight: 600;
				@include transition($transition);
				@include breakpoint(lg) {
					color: $title-color;
				}
				@include breakpoint(xl) {
					color: $white-color;
				}
			}
		}
	}
}