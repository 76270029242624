@charset "UTF-8";
.header-logo {
  display: inline-block;
  width: auto;
}
.header-logo a {
  display: inline-block;
  outline: none;
}

.header .primary-menu {
  background: transparent;
  position: fixed;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 992px) {
  .header .primary-menu .menu-area {
    margin: 0 -15px;
  }
}

.main-menu {
  list-style: none;
  margin: 0;
  justify-content: center;
  width: auto;
}
.main-menu li {
  padding: 0;
  position: relative;
}
.main-menu li.active a::after {
  transform: scaleX(1);
}
.main-menu li a {
  color: #000;
  padding: 18px 10px;
  display: inline-block;
  margin: 0 5px;
  font-size: 17px;
  font-weight: 400;
  position: relative;
  text-decoration: none;
  font-family: "Frank Ruhl Libre", serif;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.main-menu li:hover > a {
  color: #ff4181;
}
.main-menu li:hover > ul {
  top: calc(100% + 0px);
  opacity: 1;
  visibility: visible;
}
.main-menu li:last-child a {
  padding-right: 0;
}
.main-menu li ul {
  position: absolute;
  top: calc(100% + 30px);
  left: 0;
  width: 270px;
  opacity: 0;
  background: #fff;
  transition: all 0.3s ease;
  visibility: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
}
.main-menu li ul li a {
  display: block;
  border-bottom: 1px solid #ececec;
}

.banner {
  height: 900px;
  position: relative;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .banner::after {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/shape/pattern.png);
    z-index: -2;
  }
}
@media (min-width: 768px) {
  .banner {
    height: 1160px;
  }
}
@media (min-width: 992px) {
  .banner {
    height: 770px;
  }
}
@media (min-width: 992px) {
  .banner .banner-area {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
.banner .banner-area .banner-content {
  max-width: 450px;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  .banner .banner-area .banner-content {
    transform: translateY(130px);
  }
}
@media (max-width: 575px) {
  .banner .banner-area .banner-content {
    transform: translateY(100px);
  }
}
@media (min-width: 1200px) {
  .banner .banner-area .banner-content {
    margin-top: 130px;
  }
}
.banner .banner-area .banner-content h1 {
  font-weight: 400;
  line-height: 42px;
  margin: 0;
}
@media (min-width: 576px) {
  .banner .banner-area .banner-content h1 {
    line-height: 70px;
  }
}
.banner .banner-area .banner-content h1 span {
  text-transform: capitalize;
  color: #ff4181;
  font-weight: 700;
}
.banner .banner-area .banner-content h1 b {
  font-weight: 700;
  color: #000;
}
.banner .banner-area .banner-content .btn {
  margin-top: 30px;
  padding: 10px 28px;
}
.banner .banner-area .banner-thumb {
  position: relative;
  text-align: center;
  transform: translateY(180px);
}
@media (min-width: 992px) {
  .banner .banner-area .banner-thumb {
    transform: translateY(120px);
  }
}
@media (min-width: 1400px) {
  .banner .banner-area .banner-thumb {
    transform: translateY(170px);
  }
}
@media (min-width: 992px) {
  .banner .banner-area .banner-thumb::before {
    top: -70px;
    left: 15px;
    background: url(../../assets/images/banner/01.png);
    background-repeat: no-repeat;
    width: 580px;
    height: 500px;
    animation: animate1 20s infinite linear;
  }
}
.banner .banner-area .banner-thumb img {
  position: relative;
  z-index: 1;
}
@media (min-width: 992px) {
  .banner.page-header {
    height: 550px;
  }
}
.banner.page-header .banner-area .banner-content {
  max-width: 100%;
}
@media (min-width: 1200px) {
  .banner.page-header .banner-area .banner-content {
    margin-top: 0px;
  }
}
.banner.page-header .banner-area .banner-content h1 span {
  margin-left: 5px;
}
.banner.page-header .banner-area .banner-content .btn {
  margin-top: 30px;
}
.banner.page-header .banner-area .banner-thumb::before {
  display: none;
}
.banner-bottom {
  background: #fff;
  padding: 20px 0;
  position: relative;
  border-bottom: 1px solid #fafafa;
}
.banner-bottom .banner-bottom-pattan {
  background: url(../../assets/images/icon/patten1.png);
  width: 50%;
  height: 100%;
  right: 0;
  bottom: 0;
}
.banner-bottom .bottom-wrapper {
  justify-content: space-between;
}
@media (min-width: 1400px) {
  .banner-bottom .bottom-wrapper {
    margin: 0 -15px;
  }
}
.banner-bottom .bottom-wrapper .post-item {
  align-items: center;
  z-index: 1;
  position: relative;
}
@media (max-width: 991px) {
  .banner-bottom .bottom-wrapper .post-item {
    width: 100%;
    justify-content: center;
    padding: 20px 0;
  }
}
@media (max-width: 767px) {
  .banner-bottom .bottom-wrapper .post-item {
    width: calc(100% / 2);
  }
}
@media (max-width: 575px) {
  .banner-bottom .bottom-wrapper .post-item {
    width: calc(100% / 1);
  }
}
.banner-bottom .bottom-wrapper .post-item .post-thumb {
  padding-right: 15px;
}
@media (max-width: 575px) {
  .banner-bottom .bottom-wrapper .post-item .post-thumb {
    padding-right: 0px;
    margin-bottom: 10px;
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .banner-bottom .bottom-wrapper .post-item .post-content {
    width: 100%;
    text-align: center;
  }
}
.banner-bottom .bottom-wrapper .post-item .post-content h5 {
  margin: 0;
}

body {
  position: relative;
}
body.d-shape .shape-top {
  background-attachment: fixed;
}
@media (min-width: 1200px) {
  body::before {
    width: 100%;
    height: 100%;
    background: url(../../assets/images/banner/bg.png);
    background-repeat: no-repeat;
    background-attachment: fixed;
    z-index: -1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
  }
}
body .shape-top, body .shape-bottom {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 1200px) {
  body .shape-top {
    top: 0;
    right: 0;
    background: url(../../assets/images/shape/01-b.png);
    background-repeat: no-repeat !important;
    width: 100%;
    height: 1920px;
    background-position: right top !important;
    z-index: 2;
    background-attachment: auto;
  }
}
@media (min-width: 1400px) {
  body .shape-top {
    background: url(../../assets/images/shape/01.png);
  }
}
@media (min-width: 1200px) {
  body .shape-bottom {
    bottom: 0;
    left: 0;
    background: url(../../assets/images/shape/02-b.png);
    background-repeat: no-repeat !important;
    width: 100%;
    height: 1920px;
    background-position: left bottom !important;
    z-index: 2;
  }
}
@media (min-width: 1400px) {
  body .shape-bottom {
    background: url(../../assets/images/shape/02.png);
  }
}

.blog-page .section-wrapper {
  margin: 0;
}
.blog-page .section-wrapper .blog {
  margin-right: -30px;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .blog {
    margin-bottom: 0;
    margin-right: -15px;
  }
}
.blog-page .section-wrapper .blog .section-wrapper .post-item {
  width: 100%;
}
.blog-page .section-wrapper .blog .section-wrapper .post-item:first-child .post-inner {
  margin-top: 0;
}
.blog-page .section-wrapper .blog .section-wrapper .post-item:last-child .post-inner {
  margin-bottom: 0;
}
.blog-page .section-wrapper .blog .section-wrapper .post-item .post-inner {
  margin: 15px 0;
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .blog .section-wrapper .post-item .post-inner {
    margin: 15px;
  }
}
.blog-page .section-wrapper .blog .section-wrapper .post-item .post-thumb a img {
  width: 100%;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner:hover .post-thumb img {
  transform: scale(1);
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content {
  align-items: flex-start;
  padding-bottom: 0;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part {
  padding: 0 20px;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part {
    padding: 0 30px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul {
  width: 100%;
  float: left;
  margin-bottom: 20px !important;
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul {
    width: 50%;
    margin-bottom: 0;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul li {
  position: relative;
  padding-left: 20px;
  font-family: "Frank Ruhl Libre", serif;
  color: #000;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul li::before {
  top: 50%;
  left: 0;
  width: 10px;
  height: 10px;
  border-radius: 50px;
  border: 1px solid #fed643;
  transform: translateY(-50%);
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content img {
  width: 100%;
  float: left;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content img {
    width: 50%;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section {
  width: 100%;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid #ededed;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section {
    padding: 20px 30px 20px 85px;
  }
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section {
    justify-content: space-between;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags {
  align-items: center;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li {
  padding: 0;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a {
  display: inline-block;
  outline: none;
  padding: 10px;
  margin: 3px;
  border: 1px solid #ececec;
  color: #000;
  text-decoration: none;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 3px;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a {
    margin: 0 3px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags li a:hover {
  background-image: linear-gradient(to left, #fed643, #ffb045, #ff8c53, #fb6c66, #ff4181);
  color: #fff;
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list {
    margin-top: 0;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li {
  padding: 0;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a {
  color: #fff;
  border-radius: 0;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a:hover i {
  color: #fff !important;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.facebook {
  background: #3b5998;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.facebook:hover {
  border-radius: 50px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.facebook:hover i {
  color: #fff;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.twitter {
  background: #55acee;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.twitter:hover {
  border-radius: 50px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.twitter:hover i {
  color: #fff;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.linkedin {
  background: #007bb5;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.linkedin:hover {
  border-radius: 50px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.linkedin:hover i {
  color: #fff;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.google {
  background: #dd4b39;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.google:hover {
  border-radius: 50px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .social-link-list li a.google:hover i {
  color: #fff;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments {
  margin-top: 30px;
  background: #fff;
  padding: 25px 20px;
  width: 100%;
  border: 10px solid #f1f6fa;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments {
    padding: 25px 30px;
  }
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments {
    margin: 0 15px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .title-border {
  font-size: 22px;
  color: #000f3a;
  margin: 0 0 28px 0;
  position: relative;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .title-border::before {
  width: 50px;
  height: 5px;
  background: #e54295;
  bottom: -20px;
  border-radius: 2px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .title-border::after {
  border-bottom: 5px dotted #e54295;
  width: 36px;
  bottom: -20px;
  left: 55px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-thumb {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  overflow: hidden;
  margin-right: 15px;
  float: left;
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-thumb {
    height: 80px;
    width: 80px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content {
  overflow: hidden;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content:last-child {
  border-bottom: none;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title .com-title-meta h4 {
  line-height: 1;
  margin: 0;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title .com-title-meta h4 a {
  color: #000;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title .com-title-meta h4 a:hover {
  color: #ff4181;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title .com-title-meta span {
  font-size: 16px;
  color: #696969;
  font-family: "Frank Ruhl Libre", serif;
  display: inline-block;
  margin-bottom: 5px;
}
@media (max-width: 575px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content .com-title .reply {
    margin-bottom: 10px;
    display: inline-block;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .com-content p {
  margin-bottom: 0;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .comment-list {
    padding-left: 30px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .comment-list .comment {
  list-style: none;
  overflow: hidden;
  border-bottom: 1px solid #dbdbdb;
  padding: 20px 0px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .comment-list .comment .comment-list .comment:last-child {
  border: none;
  padding-bottom: 0px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond {
  background: #fff;
  padding: 25px 20px 40px;
  margin-top: 30px;
  border: 10px solid #f1f6fa;
}
@media (min-width: 576px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond {
    padding: 25px 30px 40px;
  }
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond {
    margin: 30px 15px 0 15px;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .title-border {
  position: relative;
  margin-bottom: 50px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .title-border::before {
  width: 50px;
  height: 5px;
  background-color: #e54295;
  bottom: -20px;
  border-radius: 2px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .title-border::after {
  border-bottom: 5px dotted #e54295;
  width: 36px;
  bottom: -20px;
  left: 55px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form {
  justify-content: space-between;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form input[type=text] {
  background: #f1f6fa;
  color: #696969;
  padding: 10px 15px;
  width: 100%;
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form input[type=text]:nth-child(1) {
    width: 48%;
    margin-right: 13px;
  }
}
@media (min-width: 768px) {
  .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form input[type=text]:nth-child(2) {
    width: 48%;
  }
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form button {
  border: none;
  cursor: pointer;
  background-image: linear-gradient(to left, #fed643, #ff4181);
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form button.btn {
  padding: 16px 38px;
}
.blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .comment-form button.btn:hover {
  background-image: linear-gradient(to left, #ff4181, #fed643);
}
.blog-page .section-wrapper .sidebar-widget {
  margin: 0 -15px;
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .sidebar-widget {
    margin: 0;
    margin-right: -15px;
  }
}
.blog-page .section-wrapper .sidebar-widget h4 {
  position: relative;
  margin-bottom: 30px;
}
.blog-page .section-wrapper .sidebar-widget h4::before {
  width: 50px;
  height: 5px;
  background-color: #e54295;
  bottom: -20px;
  border-radius: 2px;
}
.blog-page .section-wrapper .sidebar-widget h4::after {
  border-bottom: 5px dotted #e54295;
  width: 36px;
  bottom: -20px;
  left: 55px;
}
.blog-page .section-wrapper .sidebar-widget .widget-search form {
  position: relative;
}
.blog-page .section-wrapper .sidebar-widget .widget-search form input {
  padding: 17px;
  background: #f7fbff;
  border: none;
  width: 100%;
  position: relative;
  outline: none;
  margin-bottom: 0;
  margin-top: 20px;
  display: inline-block;
}
.blog-page .section-wrapper .sidebar-widget .widget-search form button {
  right: 0px;
  background: transparent;
  color: #858585;
  line-height: 1;
  bottom: 0px;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 53px;
  padding: 0;
}
.blog-page .section-wrapper .sidebar-widget .widget-search form button:hover {
  background: #e8f0fe;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li {
  width: 100%;
  margin: 5px 0;
  list-style: none;
  overflow: hidden;
  border-bottom: 1px solid #ededed;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li:hover span {
  color: #ff4181;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li:last-child {
  border-bottom: none;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li a {
  width: calc(100% - 50px);
  float: left;
  display: inline-block;
  text-transform: capitalize;
  padding: 10px 0;
  padding-left: 15px;
  position: relative;
  color: #696969;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li a:hover {
  color: #ff4181;
  padding-left: 25px;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li a:hover::before {
  color: #ff4181;
  padding-left: 10px;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li a::before {
  position: absolute;
  content: "";
  font-family: IcoFont !important;
  font-weight: 600;
  font-size: 12px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-page .section-wrapper .sidebar-widget .post-catagori ul li span {
  width: 50px;
  float: right;
  font-size: 18px;
  color: #696969;
  text-align: center;
  padding: 4px 0px;
  margin-top: 3px;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li {
  align-items: center;
  justify-content: space-around;
  padding: 15px 0;
  list-style: none;
  border-bottom: 1px solid #ececec;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li:last-child {
  border-bottom: none;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-content {
  width: calc(100% - 90px);
  padding-right: 10px;
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-content {
    width: 70%;
  }
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-content h6 a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-content h6 a:hover {
  color: #ff4181;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-thumb {
  width: 90px;
  text-align: right;
}
@media (min-width: 992px) {
  .blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-thumb {
    width: 30%;
  }
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-thumb a {
  display: block;
}
.blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li .rec-thumb a img {
  display: block;
}
.blog-page .section-wrapper .sidebar-widget .widget-tags ul {
  padding-top: 20px !important;
}
.blog-page .section-wrapper .sidebar-widget .widget-tags ul li {
  padding: 0;
  margin: 2px;
}
.blog-page .section-wrapper .sidebar-widget .widget-tags ul li a {
  padding: 8px 11px;
  color: #696969;
  border-radius: 1px;
  border: 1px solid #ececec;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog-page .section-wrapper .sidebar-widget .widget-tags ul li a:hover {
  color: #fff;
  background: linear-gradient(to left, #fed643, #ff4181);
}
.blog-page .section-wrapper .sidebar-widget .widget-instagram ul {
  justify-content: center;
  padding-top: 20px !important;
  margin: -3px !important;
}
.blog-page .section-wrapper .sidebar-widget .widget-instagram ul li {
  padding: 0;
  margin: 3px;
}
.blog-page .section-wrapper .sidebar-widget .widget-instagram ul li a {
  display: block;
}
.blog-page .section-wrapper .sidebar-widget .widget-instagram ul li a img {
  display: block;
}
.blog-page .section-wrapper .sidebar-widget .widget-add a {
  padding-top: 20px;
  display: block;
}
.blog-page .section-wrapper .sidebar-widget .widget-add a img {
  display: block;
  width: 100%;
}
.pagination-area {
  padding: 20px 0 0;
}
.pagination-area .pagination {
  margin: 0;
  align-items: center;
}
.pagination-area .pagination li {
  list-style: none;
}
.pagination-area .pagination li.dot {
  font-weight: 700;
  padding: 10px;
  font-size: 16px;
}
.pagination-area .pagination li.prev {
  margin-right: 5px;
}
.pagination-area .pagination li.prev a i {
  font-weight: 600;
}
.pagination-area .pagination li.next {
  margin-left: 5px;
}
.pagination-area .pagination li.next a i {
  font-weight: 600;
}
.pagination-area .pagination li a {
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: #fff;
  color: #000;
  margin: 0 2px;
  text-transform: capitalize;
  font-weight: 600;
  border: 1px solid #ececec;
  border-radius: 50px;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pagination-area .pagination li a:hover {
  background-image: linear-gradient(to left, #fed643, #ff4181);
  color: #fff !important;
}
.pagination-area .pagination li a.active {
  background-image: linear-gradient(to left, #fed643, #ff4181);
  color: #fff !important;
}

.about {
  position: relative;
  overflow: hidden;
}
.about .section-wrapper {
  position: relative;
  z-index: 1;
}
.about .section-wrapper .title {
  max-width: 545px;
}
.about .section-wrapper .title h2 {
  font-weight: 300;
  margin-bottom: 15px;
  line-height: 40px;
}
.about .section-wrapper .title h2 span {
  font-weight: 700;
}
.about .section-wrapper .title h3 {
  font-weight: 400;
  margin: 0;
}
.about .section-wrapper .about-left {
  width: calc(100% / 1);
}
@media (min-width: 992px) {
  .about .section-wrapper .about-left {
    width: calc(100% / 2 - 15px);
    margin-right: 15px;
  }
}
.about .section-wrapper .about-left .about-thumb {
  margin-bottom: 60px;
}
@media (min-width: 992px) {
  .about .section-wrapper .about-left .about-thumb {
    margin-bottom: 0;
    margin-top: 40px;
  }
}
.about .section-wrapper .about-right {
  width: calc(100% / 1);
}
@media (min-width: 992px) {
  .about .section-wrapper .about-right {
    width: calc(100% / 2 - 15px);
    margin-left: 15px;
  }
}
.about .section-wrapper .about-right .about-item .about-content ul li .name {
  width: 130px;
}
.about .section-wrapper .about-right .about-item .about-content ul li .name p {
  font-size: 16px;
  margin-bottom: 0;
  font-family: "Frank Ruhl Libre", serif;
}
.about .section-wrapper .about-right .about-item .about-content ul li .info {
  width: calc(100% - 130px);
  font-weight: 400;
}
.about .section-wrapper .about-right .about-item .about-content ul li .info p {
  font-size: 16px;
  color: #000;
  position: relative;
  padding-left: 20px;
  margin-bottom: 0;
}
.about .section-wrapper .about-right .about-item .about-content ul li .info p::after {
  position: absolute;
  content: ":";
  top: 50%;
  left: 0;
  font-weight: 700;
  color: #000;
  transform: translateY(-50%);
}
.about .section-wrapper .about-right .about-item .btn {
  margin-top: 40px;
}

.sponsor {
  padding: 30px 0;
}
.sponsor .section-wrapper .sponsor-slider {
  overflow: hidden;
}
.sponsor .section-wrapper .sponsor-slider .swiper-wrapper {
  display: flex;
  align-items: baseline;
}
.sponsor .section-wrapper .sponsor-slider .swiper-wrapper .sponsor-item {
  text-align: center;
  cursor: all-scroll;
}
.sponsor .section-wrapper .sponsor-slider .swiper-wrapper .sponsor-item:hover img {
  opacity: 0.5;
}
.sponsor .section-wrapper .sponsor-slider .swiper-wrapper .sponsor-item img {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.experience {
  background-repeat: no-repeat;
  background-position: bottom;
}
.experience .section-wrapper .exp-item {
  width: 100%;
}
@media (min-width: 992px) {
  .experience .section-wrapper .exp-item {
    width: calc(100% / 2);
  }
}
.experience .section-wrapper .exp-item:nth-child(even) .exp-inner {
  margin-right: 0;
}
.experience .section-wrapper .exp-item:nth-child(odd) .exp-inner {
  margin-left: 0;
}
.experience .section-wrapper .exp-item .exp-inner {
  margin: 15px 0;
  padding: 25px;
  border-radius: 1px;
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 992px) {
  .experience .section-wrapper .exp-item .exp-inner {
    padding: 34px;
  }
}
@media (min-width: 992px) {
  .experience .section-wrapper .exp-item .exp-inner {
    margin: 15px;
  }
}
.experience .section-wrapper .exp-item .exp-inner:hover {
  transform: translateY(-10px);
}
.experience .section-wrapper .exp-item .exp-inner:hover .exp-content h3 {
  color: #ff4181;
}
.experience .section-wrapper .exp-item .exp-inner .exp-thumb {
  position: relative;
  align-items: center;
}
.experience .section-wrapper .exp-item .exp-inner .exp-thumb h6 {
  margin: 0;
  width: 100%;
  font-weight: 400;
}
@media (min-width: 576px) {
  .experience .section-wrapper .exp-item .exp-inner .exp-thumb h6 {
    padding-left: 20px;
    width: auto;
  }
}
.experience .section-wrapper .exp-item .exp-inner .exp-thumb .exp-cata {
  position: absolute;
  top: 0;
  right: 0;
}
@media (min-width: 576px) {
  .experience .section-wrapper .exp-item .exp-inner .exp-thumb .exp-cata {
    top: 50%;
    transform: translateY(-50%);
  }
}
.experience .section-wrapper .exp-item .exp-inner .exp-thumb .exp-cata span {
  position: relative;
  font-size: 16px;
  padding: 6px 14px;
  background-image: linear-gradient(to right, #fed643, #ff4181);
  color: #fff;
  border-radius: 1px;
}
.experience .section-wrapper .exp-item .exp-inner .exp-thumb .exp-cata span::before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  left: -5px;
  top: 50%;
  transform: translateY(-50%) rotate(-45deg);
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fed643;
}
.experience .section-wrapper .exp-item .exp-inner .exp-content h3 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-bottom: 10px;
}
.experience .section-wrapper .exp-item .exp-inner .exp-content p {
  line-height: 24px;
  margin: 0;
}
.experience .text-center {
  margin-top: 40px;
}

.services .section-header {
  max-width: 690px;
}
.services .section-wrapper .service-item {
  width: 100%;
}
@media (min-width: 992px) {
  .services .section-wrapper .service-item {
    width: calc(100% / 2);
  }
}
.services .section-wrapper .service-item:nth-child(even) .service-inner {
  margin-right: 0;
}
.services .section-wrapper .service-item:nth-child(odd) .service-inner {
  margin-left: 0;
}
.services .section-wrapper .service-item:nth-child(2) .service-inner {
  background-image: linear-gradient(to top, #645cff, #ff5b8a);
}
.services .section-wrapper .service-item:nth-child(2) .service-inner .service-content ul li::before {
  border-color: #ff4181;
}
.services .section-wrapper .service-item:nth-child(2) .service-inner .service-thumb {
  animation: animate1 10s infinite linear;
}
.services .section-wrapper .service-item:nth-child(3) .service-inner {
  background-image: linear-gradient(to top, #cc00ff, #8484fb);
}
.services .section-wrapper .service-item:nth-child(3) .service-inner .service-content ul li::before {
  border-color: #e7b53f;
}
.services .section-wrapper .service-item:nth-child(3) .service-inner .service-thumb {
  animation: animate1 13s infinite linear;
}
.services .section-wrapper .service-item:nth-child(4) .service-inner {
  background-image: linear-gradient(to top, #81dcfd, #f4d63d);
}
.services .section-wrapper .service-item:nth-child(4) .service-inner .service-content ul li::before {
  border-color: #645cff;
}
.services .section-wrapper .service-item:nth-child(4) .service-inner .service-thumb {
  animation: animate1 15s infinite linear;
}
.services .section-wrapper .service-item .service-inner {
  margin: 15px 0;
  border-radius: 1px;
  background-image: linear-gradient(to top, #00a9ce, #8af5d1);
  position: relative;
  align-items: center;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .services .section-wrapper .service-item .service-inner {
    margin: 15px;
  }
}
@media (min-width: 576px) {
  .services .section-wrapper .service-item .service-inner::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../assets/images/service/service-bg.png);
    background-position: left bottom;
    background-repeat: no-repeat;
  }
}
.services .section-wrapper .service-item .service-inner .service-content {
  width: 100%;
  padding: 32px;
  padding-right: 0;
  position: relative;
  z-index: 1;
}
@media (min-width: 576px) {
  .services .section-wrapper .service-item .service-inner .service-content {
    width: calc(100% / 2);
  }
}
.services .section-wrapper .service-item .service-inner .service-content h3 {
  margin: 0;
  margin-bottom: 5px;
}
.services .section-wrapper .service-item .service-inner .service-content p {
  margin-bottom: 10px;
}
.services .section-wrapper .service-item .service-inner .service-content ul {
  margin: 0;
  padding: 0;
}
.services .section-wrapper .service-item .service-inner .service-content ul li {
  list-style: none;
  position: relative;
  padding: 3px 0;
  padding-left: 20px;
}
.services .section-wrapper .service-item .service-inner .service-content ul li::before {
  position: absolute;
  content: "";
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 2px solid #80f0d1;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.services .section-wrapper .service-item .service-inner .service-thumb {
  width: 100%;
  position: relative;
  padding-right: 10px;
  text-align: center;
  animation: animate1 8s infinite linear;
}
@media (min-width: 576px) {
  .services .section-wrapper .service-item .service-inner .service-thumb {
    width: calc(100% / 2);
    text-align: right;
  }
}
.services .section-wrapper .service-item .service-inner .service-thumb img {
  float: none;
}
@media (min-width: 576px) {
  .services .section-wrapper .service-item .service-inner .service-thumb img {
    float: right;
  }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
  .services .section-wrapper .service-item .service-inner::before {
    display: none;
  }
  .services .section-wrapper .service-item .service-inner .service-content {
    padding: 39px 0px 39px 25px;
  }
  .services .section-wrapper .service-item .service-inner .service-content h3 {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content p {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content ul li {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content ul li::before {
    border-color: #fff !important;
  }
}
@media (max-width: 575px) {
  .services .section-wrapper .service-item .service-inner::before {
    display: none;
  }
  .services .section-wrapper .service-item .service-inner .service-content {
    padding: 39px 0px 39px 25px;
  }
  .services .section-wrapper .service-item .service-inner .service-content h3 {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content p {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content ul li {
    color: #fff;
  }
  .services .section-wrapper .service-item .service-inner .service-content ul li::before {
    border-color: #fff !important;
  }
}
.portfolio .portfolio-top {
  align-items: center;
}
.portfolio .portfolio-top .portfolio-left {
  width: 100%;
}
@media (min-width: 992px) {
  .portfolio .portfolio-top .portfolio-left {
    width: calc(100% / 2);
  }
}
.portfolio .portfolio-top .portfolio-right {
  width: 100%;
}
@media (min-width: 992px) {
  .portfolio .portfolio-top .portfolio-right {
    width: calc(100% / 2);
  }
}
.portfolio .portfolio-top .portfolio-right ul {
  margin: 0;
  justify-content: center;
  margin-bottom: 30px;
}
@media (min-width: 992px) {
  .portfolio .portfolio-top .portfolio-right ul {
    justify-content: flex-end;
    margin-bottom: 0;
  }
}
@media (min-width: 1200px) {
  .portfolio .portfolio-top .portfolio-right ul {
    margin-right: -15px;
  }
}
.portfolio .portfolio-top .portfolio-right ul li {
  padding: 11px 22px;
  margin-right: 10px;
  margin-bottom: 10px;
  color: #000;
  background: #f2f2f2;
  font-family: "Frank Ruhl Libre", serif;
  font-size: 16px;
  cursor: pointer;
  border-radius: 1px;
}
.portfolio .portfolio-top .portfolio-right ul li span {
  font-weight: 500;
  padding: 0 5px;
}
@media (min-width: 1200px) {
  .portfolio .portfolio-top .portfolio-right ul li:last-child {
    margin-right: 0;
  }
}
.portfolio .portfolio-top .portfolio-right ul li.active {
  color: #fff;
  background-image: linear-gradient(to right, #fed643, #ff4181);
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
}
.portfolio .section-wrapper {
  margin: 0 -7.5px;
}
@media (min-width: 768px) {
  .portfolio .section-wrapper .grid {
    margin: 0 -7.5px;
  }
}
.portfolio .section-wrapper .grid .grid-item {
  width: 100%;
}
@media (min-width: 768px) {
  .portfolio .section-wrapper .grid .grid-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 992px) {
  .portfolio .section-wrapper .grid .grid-item {
    width: calc(100% / 3);
  }
}
.portfolio .section-wrapper .grid .grid-item .grid-inner {
  margin: 7.5px;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb {
  position: relative;
  display: block;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb a {
  display: block;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb a img {
  display: block;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb img {
  display: block;
  width: 100%;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb .thumb-icon {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb .thumb-icon a {
  width: 60px;
  height: 60px;
  line-height: 60px;
  border-radius: 50%;
  background: #ff4181;
  color: #fff;
  text-align: center;
  border: 3px solid #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb .thumb-icon a:hover {
  background: #fff;
  color: #ff4181;
  border-color: #ff4181;
}
.portfolio .section-wrapper .grid .grid-item .grid-inner .thumb .thumb-icon a i {
  font-size: 20px;
}

.pricing {
  background-repeat: no-repeat;
  background-position: left bottom;
  background-size: cover;
}
.pricing .section-header {
  max-width: 500px;
}
.pricing .section-wrapper {
  justify-content: center;
}
.pricing .section-wrapper .pricing-item {
  width: calc(100% / 1);
}
@media (min-width: 768px) {
  .pricing .section-wrapper .pricing-item {
    width: calc(100% / 2);
  }
}
@media (min-width: 1200px) {
  .pricing .section-wrapper .pricing-item {
    width: calc(100% / 3);
  }
}
.pricing .section-wrapper .pricing-item:nth-child(1) .pricing-inner {
  margin-left: 0;
}
@media (max-width: 1199px) {
  .pricing .section-wrapper .pricing-item:nth-child(2) .pricing-inner {
    margin-right: 0;
  }
}
@media (min-width: 1200px) {
  .pricing .section-wrapper .pricing-item:nth-child(3) .pricing-inner {
    margin-right: 0;
  }
}
.pricing .section-wrapper .pricing-item .pricing-inner {
  padding: 40px;
  margin: 15px 0;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 3px 15px 0px rgba(0, 0, 0, 0.1);
  position: relative;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 768px) {
  .pricing .section-wrapper .pricing-item .pricing-inner {
    margin: 15px;
  }
}
@media (min-width: 1200px) {
  .pricing .section-wrapper .pricing-item .pricing-inner {
    margin: 0 15px;
  }
}
.pricing .section-wrapper .pricing-item .pricing-inner::before {
  background-image: linear-gradient(to right, #ffda41, #ff4181);
}
.pricing .section-wrapper .pricing-item .pricing-inner:hover {
  transform: translateY(-10px);
}
.pricing .section-wrapper .pricing-item .pricing-inner:hover .pricing-content .pricing-header h3 {
  color: #ff4181;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-header h3 {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-body .pricing-title {
  padding: 30px 0;
  padding-bottom: 15px;
  border-top: 1px solid #ececec;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-body .pricing-title h5 {
  margin: 0;
  text-transform: capitalize;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-body .pricing-title h3 {
  font-size: 42px;
  line-height: 46px;
  color: #ff4181;
  margin: 0;
  font-weight: 700;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-body .pricing-title h3 span {
  font-size: 16px;
  color: #696969;
  font-weight: 400;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-body .pricing-title p {
  margin-bottom: 0;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-list {
  margin-bottom: 30px !important;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-list li {
  position: relative;
  padding-left: 25px;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-list li::before {
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  content: "" !important;
  font-family: IcoFont !important;
  color: #ff4181;
  font-size: 20px;
}
.pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .text-center .btn {
  font-family: "Frank Ruhl Libre", serif;
}

.testimonial {
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: bottom;
}
.testimonial .section-header {
  max-width: 510px;
  position: relative;
  z-index: 1;
}
.testimonial .section-wrapper {
  overflow: hidden;
}
.testimonial .section-wrapper .testimonial-slider {
  padding: 10px;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner {
  background: #fff;
  box-shadow: 0px 10px 35px 0px #f0edf37a;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner:hover {
  box-shadow: 0px 3px 30px 0px rgba(0, 0, 0, 0.1);
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head {
  padding: 20px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ececec;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .testimonial-thumb {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .testimonial-thumb img {
  width: 100%;
  height: 100%;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .name-des {
  padding-left: 10px;
  padding-right: 20px;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .name-des h5 {
  font-weight: 600;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .name-des h6 {
  font-weight: 400;
  font-family: "Roboto", sans-serif;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-body {
  padding: 20px;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-body p {
  margin-bottom: 0;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-footer {
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  padding-bottom: 20px;
}
.testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-footer ul li i {
  color: #fed643;
}

.blog .blog-header-part {
  justify-content: center;
  align-items: center;
}
@media (min-width: 768px) {
  .blog .blog-header-part {
    justify-content: space-between;
  }
}
.blog .blog-header-part .section-header {
  max-width: 400px;
}
@media (max-width: 575px) {
  .blog .blog-header-part .section-header {
    margin-bottom: 20px !important;
  }
}
@media (max-width: 575px) {
  .blog .blog-header-part .btn {
    display: none;
  }
}
@media (min-width: 1200px) {
  .blog .blog-header-part .btn {
    margin-right: -15px;
  }
}
.blog .section-wrapper .post-item {
  width: calc(100% / 1);
}
@media (min-width: 992px) {
  .blog .section-wrapper .post-item {
    width: calc(100% / 2);
  }
}
.blog .section-wrapper .post-item:first-child .post-inner {
  margin-left: 0;
}
.blog .section-wrapper .post-item:last-child .post-inner {
  margin-right: 0;
}
.blog .section-wrapper .post-item .post-inner {
  margin: 15px 0;
  background: #f4f9fd;
}
@media (min-width: 992px) {
  .blog .section-wrapper .post-item .post-inner {
    margin: 0 15px;
  }
}
.blog .section-wrapper .post-item .post-inner:hover .post-thumb a img {
  transform: scale(1.03);
}
.blog .section-wrapper .post-item .post-inner:hover .post-content .content-part .entry-header h3 a {
  color: #ff4181;
}
.blog .section-wrapper .post-item .post-inner .post-thumb {
  overflow: hidden;
}
.blog .section-wrapper .post-item .post-inner .post-thumb a {
  display: block;
}
.blog .section-wrapper .post-item .post-inner .post-thumb a img {
  display: block;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog .section-wrapper .post-item .post-inner .post-content {
  align-items: center;
  padding: 20px 0 35px;
  margin: 10px;
  margin-top: -30px;
  background: #fff;
  z-index: 1;
  position: relative;
  transform: translateY(-10px);
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content {
    padding: 35px 0;
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left {
  width: 100%;
  text-align: left;
  padding: 0 20px !important;
  align-items: center;
  margin-bottom: 20px !important;
  padding-bottom: 15px !important;
  border-bottom: 1px solid #ececec;
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-left {
    display: block !important;
    width: 60px;
    text-align: center;
    padding: 0 !important;
    margin-bottom: 0px !important;
    border-bottom: none;
    border-right: 1px solid #ececec;
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li {
  position: relative;
  padding: 0;
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-left li {
    border-bottom: 1px solid #ececec;
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li:last-child {
  border-bottom: none;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li:hover .social-media-list {
  transform: scaleX(1);
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-left li:hover .social-media-list {
    transform: scaleY(1);
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li a {
  padding: 0 20px;
  display: block;
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-left li a {
    padding: 10px 0;
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li p {
  margin-bottom: 0;
  padding-bottom: 5px;
  text-align: center;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transform: scaleX(0);
  width: 320px;
  z-index: 1;
  top: 0px;
  left: 100%;
  transform-origin: left;
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list {
    display: block !important;
    top: 100%;
    left: 0;
    width: 100%;
    transform-origin: top;
    transform: scaleY(0);
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list li {
  padding: 0;
  text-align: center;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list li a {
  padding: 15px;
  display: block;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list li a i {
  font-size: 16px;
  color: #fff;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part {
  width: 100%;
  padding: 0 20px;
}
@media (min-width: 576px) {
  .blog .section-wrapper .post-item .post-inner .post-content .content-part {
    width: calc(100% - 60px);
    padding: 0 30px;
  }
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta {
  font-size: 16px;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta .byline {
  font-family: "Frank Ruhl Libre", serif;
  margin-bottom: 0;
  margin-right: 10px;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta .byline a {
  color: #ff4181;
  margin-left: 5px;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta .posted-on {
  font-family: "Frank Ruhl Libre", serif;
  margin-bottom: 0;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta .posted-on a {
  color: #ff4181;
  margin-left: 5px;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header h3 {
  line-height: 30px;
  margin: 10px 0;
}
.blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header h3 a {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.contact {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}
.contact .section-header {
  max-width: 710px;
}
.contact .section-wrapper .maps#gmap {
  height: 450px;
}
.contact .section-wrapper .banner-bottom {
  border-bottom: none;
  padding: 40px 0;
}
.contact .section-wrapper form .form-group input {
  border-color: #ececec;
}
.contact .section-wrapper form .form-group textarea {
  border-color: #ececec;
  margin-bottom: 30px;
}
.contact .section-wrapper form .form-group .btn {
  padding: 11px 39px;
  z-index: 1;
}

.maps {
  height: 450px;
}
.maps iframe {
  width: 100%;
  height: 100%;
}

/* @@@@@@@@@@ global css start here @@@@@@ */
.padding-tb {
  padding: 60px 0;
}
@media (min-width: 992px) {
  .padding-tb {
    padding: 100px 0;
  }
}

.padding-60 {
  padding: 60px 0;
}

.padding-30 {
  padding: 30px;
}

.section-header {
  max-width: 800px;
  margin-bottom: 45px !important;
}
@media (min-width: 1200px) {
  .section-header {
    margin: 0 -15px;
  }
}
.section-header .title h2 {
  font-weight: 300 !important;
  line-height: 40px;
}
.section-header .title h2 span {
  color: #ff4181;
  font-weight: 700;
}

.bg-white {
  padding: 10px 0;
  background: #fff !important;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
}
.bg-white .main-menu li.active a {
  color: #000 !important;
}

.banner-wrapper.banner-none {
  display: none;
}

@media (min-width: 1200px) {
  .section-wrapper {
    margin: 0 -15px;
  }
}

.social-link-list, .banner-bottom .bottom-wrapper, .banner-bottom .bottom-wrapper .post-item, .blog-page .section-wrapper, .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section, .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags, .blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post li, .blog-page .section-wrapper .sidebar-widget .widget-tags ul, .blog-page .section-wrapper .sidebar-widget .widget-instagram ul, .about .section-wrapper, .about .section-wrapper .about-right .about-item .about-content ul li, .experience .section-wrapper, .experience .section-wrapper .exp-item .exp-inner .exp-thumb, .services .section-wrapper, .services .section-wrapper .service-item .service-inner, .portfolio .portfolio-top, .portfolio .portfolio-top .portfolio-right ul, .portfolio .section-wrapper .grid, .pricing .section-wrapper, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .testi-top, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-footer, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-footer ul, .blog .blog-header-part, .blog .section-wrapper, .blog .section-wrapper .post-item .post-inner .post-content, .blog .section-wrapper .post-item .post-inner .post-content .content-left, .blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list, .blog .section-wrapper .post-item .post-inner .post-content .content-part .entry-header .entry-meta {
  display: flex;
  flex-wrap: wrap;
}

blockquote::before, .footer::after, .banner::after, .banner .banner-area .banner-thumb::before, .banner-bottom .banner-bottom-pattan, body::before, body .shape-top, body .shape-bottom, .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul li::before, .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .title-border::before, .blog-page .section-wrapper .blog.blog-single .section-wrapper .comments .title-border::after, .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .title-border::before, .blog-page .section-wrapper .blog.blog-single .section-wrapper .comment-respond .add-comment .title-border::after, .blog-page .section-wrapper .sidebar-widget h4::before, .blog-page .section-wrapper .sidebar-widget h4::after, .blog-page .section-wrapper .sidebar-widget .widget-search form button, .portfolio .section-wrapper .grid .grid-item .grid-inner .thumb .thumb-icon, .pricing .section-wrapper .pricing-item .pricing-inner::before, .pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-list li::before, .blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list {
  position: absolute;
  content: "";
}

.blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .content-part .entry-content ul, .blog-page .section-wrapper .blog.blog-single .section-wrapper .post-item .post-inner .post-content .tags-section .tags, .blog-page .section-wrapper .sidebar-widget .post-catagori ul, .blog-page .section-wrapper .sidebar-widget .widget-rec-post .recent-post, .blog-page .section-wrapper .sidebar-widget .widget-tags ul, .blog-page .section-wrapper .sidebar-widget .widget-instagram ul, .about .section-wrapper .about-right .about-item .about-content ul, .pricing .section-wrapper .pricing-item .pricing-inner .pricing-content .pricing-list, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .name-des h5, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-head .name-des h6, .testimonial .section-wrapper .testimonial-slider .testimonial-item .testimonial-inner .testimonial-footer ul, .blog .section-wrapper .post-item .post-inner .post-content .content-left, .blog .section-wrapper .post-item .post-inner .post-content .content-left li .social-media-list, .contact .section-wrapper .banner-bottom .bottom-wrapper {
  margin: 0;
  padding: 0;
}

.pricing .section-wrapper .pricing-item .pricing-inner::before {
  width: 100%;
  height: 6px;
  top: 0px;
  left: 0;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.blog-page .section-wrapper .sidebar-widget .widget-search, .blog-page .section-wrapper .sidebar-widget .post-catagori, .blog-page .section-wrapper .sidebar-widget .widget-rec-post, .blog-page .section-wrapper .sidebar-widget .widget-tags, .blog-page .section-wrapper .sidebar-widget .widget-instagram, .blog-page .section-wrapper .sidebar-widget .widget-add {
  border: 10px solid #f1f6fa;
}

.blog-page .section-wrapper .sidebar-widget .widget-search, .blog-page .section-wrapper .sidebar-widget .post-catagori, .blog-page .section-wrapper .sidebar-widget .widget-rec-post, .blog-page .section-wrapper .sidebar-widget .widget-tags, .blog-page .section-wrapper .sidebar-widget .widget-instagram, .blog-page .section-wrapper .sidebar-widget .widget-add {
  padding: 20px 20px 30px;
}

.blog-page .section-wrapper .sidebar-widget .post-catagori, .blog-page .section-wrapper .sidebar-widget .widget-rec-post, .blog-page .section-wrapper .sidebar-widget .widget-tags, .blog-page .section-wrapper .sidebar-widget .widget-instagram, .blog-page .section-wrapper .sidebar-widget .widget-add {
  margin-top: 35px;
}

.text-center {
  text-align: center;
  z-index: 1;
  position: relative;
}

.btn {
  position: relative;
  color: #fff;
  padding: 10px 30px;
  border-radius: 2px;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  font-family: "Frank Ruhl Libre", serif;
  box-shadow: 0px 1px 2px 1px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn::before {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease;
  background-image: linear-gradient(to left, #fed643, #ff4181);
}
.btn::after {
  position: absolute;
  width: 100%;
  height: 100%;
  content: "";
  background-image: linear-gradient(to right, #fed643, #ff4181);
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease;
}
.btn img {
  margin-left: 24px;
  transform: translateY(6px);
}
.btn span {
  transform: translateY(-1px);
  display: inline-block;
}
.btn:hover {
  color: #fff;
}
.btn:hover::after {
  opacity: 0;
}

.scrollToTop {
  position: fixed;
  bottom: -30%;
  right: 5%;
  z-index: 9;
  width: 40px;
  height: 40px;
  line-height: 40px;
  background: #ff4181;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.scrollToTop:hover {
  color: #fff;
  background: #fed643;
}

.social-link-list {
  margin-bottom: 0;
  margin-left: 0;
  justify-content: center;
}
.social-link-list li {
  list-style: none;
}
.social-link-list li a {
  width: 40px;
  height: 40px;
  line-height: 37px;
  text-align: center;
  color: #ff4181;
  background: #fff;
  border-radius: 50%;
  margin: 0 5px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border: 2px solid transparent;
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
}

.facebook {
  background: #3b5998;
}
.facebook:hover {
  background: #fff;
  border-color: #3b5998 !important;
}
.facebook:hover i {
  color: #3b5998 !important;
}

.twitter {
  background: #55acee;
  border-color: #55acee !important;
}
.twitter:hover {
  background: #fff;
}
.twitter:hover i {
  color: #55acee !important;
}

.twitter-sm {
  background: #55acee;
}
.twitter-sm:hover {
  background: #fff;
  border-color: #55acee;
}
.twitter-sm:hover i {
  color: #55acee !important;
}

.google {
  background: #dd4b39;
}
.google:hover {
  background: #fff;
  border-color: #dd4b39 !important;
}
.google:hover i {
  color: #dd4b39 !important;
}

.linkedin {
  background: #007bb5;
}
.linkedin:hover {
  background: #fff;
  border-color: #007bb5 !important;
}
.linkedin:hover i {
  color: #007bb5 !important;
}

.instagram {
  background: #e4405f;
}
.instagram:hover {
  background: #fff;
  border-color: #e4405f !important;
}
.instagram:hover i {
  color: #e4405f !important;
}

.tumblr {
  background: #34465d;
}
.tumblr:hover {
  background: #fff;
  border-color: #34465d !important;
}
.tumblr:hover i {
  color: #34465d !important;
}

.behance {
  background: #1769ff;
}
.behance:hover {
  background: #fff;
  border-color: #1769ff !important;
}
.behance:hover i {
  color: #1769ff !important;
}

.youtube {
  background: #cd201f;
}
.youtube:hover {
  background: #fff;
  border-color: #cd201f !important;
}
.youtube:hover i {
  color: #cd201f !important;
}

#scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #fff;
}

#scrollbar::-webkit-scrollbar {
  width: 4px;
  background-color: #fff;
}

#scrollbar::-webkit-scrollbar-thumb {
  background-color: #ff4181;
  border-radius: 8px;
}

.box-shadow-style {
  position: relative;
  z-index: 4;
  background: #fff;
  max-width: 100%;
  margin: auto;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  .box-shadow-style {
    max-width: 1250px;
  }
}
@media (min-width: 1400px) {
  .box-shadow-style {
    max-width: 1400px;
  }
}

.category {
  font-size: 16px;
  padding: 6px 24px;
  margin-bottom: 15px;
  color: #fff;
  position: relative;
  display: inline-block;
  font-weight: 400;
  font-family: "Frank Ruhl Libre", serif;
  background-image: linear-gradient(to right, #fed643, #ff4181);
  background-repeat: no-repeat;
}
.category::after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-left: 0px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fed643;
  bottom: -8px;
  left: 0;
}

.gold-title {
  margin: -10px 0;
  color: #fff;
}

.cd-words-wrapper {
  display: inline-block;
  position: relative;
  text-align: left;
}

.cd-words-wrapper b {
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  left: 0;
  top: 0;
  font-style: normal;
  font-weight: 700;
}

.cd-words-wrapper i {
  font-style: normal;
}

.cd-words-wrapper b.is-visible {
  position: relative;
}

.no-js .cd-words-wrapper b {
  opacity: 0;
}

.no-js .cd-words-wrapper b.is-visible {
  opacity: 1;
}

.cd-headline.clip .cd-words-wrapper {
  overflow: hidden;
  vertical-align: top;
}

.cd-headline.clip b {
  opacity: 0;
}

.cd-headline.clip b.is-visible {
  opacity: 1;
}

@keyframes animate1 {
  0% {
    -moz-transform: translate(3%, 0%);
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%);
  }
  25% {
    -moz-transform: translate(0%, 3%);
    -webkit-transform: translate(0%, 3%);
    transform: translate(0%, 3%);
  }
  50% {
    -moz-transform: translate(3%, 3%);
    -webkit-transform: translate(3%, 3%);
    transform: translate(3%, 3%);
  }
  75% {
    -moz-transform: translate(0%, 3%);
    -webkit-transform: translate(0%, 3%);
    transform: translate(0%, 3%);
  }
  100% {
    -moz-transform: translate(3%, 0%);
    -webkit-transform: translate(3%, 0%);
    transform: translate(3%, 0%);
  }
}
#particles-js, #particles-js2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  background-image: url("");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

@media (min-width: 1200px) {
  .container,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    max-width: 1170px !important;
  }
}
.footer {
  padding: 85px 0;
  position: relative;
  z-index: 3;
}
@media (max-width: 1199px) {
  .footer {
    background-image: linear-gradient(to left, #fed643, #e15377);
  }
}
.footer::after {
  width: 100%;
  height: 200%;
  top: -100%;
  right: 0;
  background-size: cover;
  background: url(../../assets/images/shape/pattern.png);
}
.footer .text-center {
  position: relative;
  z-index: 9;
}
.footer .text-center .social-link-list {
  margin-top: 0;
}
.footer .text-center .social-link-list li a {
  margin: 7px;
  font-size: 16px;
}
.footer .text-center .social-link-list li a:hover {
  transform: translateY(-5px);
}
.footer .text-center .social-link-list li a i.icofont-rss {
  transform: translateY(-2px) translateX(2px);
  display: inline-block;
}
.footer .text-center .footer-logo {
  padding: 10px 0;
}
.footer .text-center p {
  margin-bottom: 0;
  color: #fff;
}
@media (min-width: 992px) {
  .footer .text-center p {
    color: #000;
  }
}
@media (min-width: 1200px) {
  .footer .text-center p {
    color: #fff;
  }
}
.footer .text-center p a {
  color: #fff;
  font-weight: 600;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
@media (min-width: 992px) {
  .footer .text-center p a {
    color: #000;
  }
}
@media (min-width: 1200px) {
  .footer .text-center p a {
    color: #fff;
  }
}

.mobile-header {
  padding: 10px 25px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 111;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 1px 10px -2px rgba(0, 0, 0, 0.1);
}
@media (min-width: 576px) {
  .mobile-header .header-logo {
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .mobile-header {
    padding: 10px 15px;
  }
}
.mobile-header .header-bar {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.mobile-header .header-bar span {
  background: #ff4181;
  width: 100%;
  height: 3px;
  display: block;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform-origin: center;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.mobile-header .header-bar span:nth-child(1) {
  margin-top: -9px;
}
.mobile-header .header-bar span:nth-child(2) {
  opacity: 1;
}
.mobile-header .header-bar span:nth-child(3) {
  margin-top: 7px;
}
.mobile-header .header-bar.m-menu-bar span:nth-child(1) {
  transform: rotate(-45deg);
  margin-top: -1px;
}
.mobile-header .header-bar.m-menu-bar span:nth-child(2) {
  opacity: 0;
}
.mobile-header .header-bar.m-menu-bar span:nth-child(3) {
  transform: rotate(45deg);
  margin-top: -1px;
}

.mobile-menu-area.m-menu-zero {
  opacity: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.5);
}
.mobile-menu-area .mobile-menu-area-inner {
  height: 100vh;
  padding: 40px 20px;
  padding-left: 0;
  overflow-y: scroll;
}
.mobile-menu-area .mobile-menu-area-inner .header-bar {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 15px;
  position: relative;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu {
  text-align: center;
  margin: 0;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li {
  list-style: none;
  padding: 0;
  position: relative;
  border-bottom: 1px solid #fafafa;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li:last-child {
  border-bottom: none;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li a {
  display: block !important;
  padding: 10px 0;
  text-transform: capitalize;
  margin: 2px 0;
  text-align: left;
  outline: none;
  color: #000;
  font-family: "Frank Ruhl Libre", serif;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li a:hover {
  color: #fed643;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li:hover ul {
  display: block;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li ul {
  display: none;
}
.mobile-menu-area .mobile-menu-area-inner .m-menu li ul li a {
  border-bottom: 1px solid #ececec;
}

nav.mobile-header.close {
  display: none;
}

/* Close Button */
.close-button {
  width: 36px;
  height: 36px;
  line-height: 36px;
  position: relative;
  right: 0;
  top: 0;
  overflow: hidden;
  text-indent: 16px;
  border: none;
  z-index: 1001;
  background: #ff4181;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
}

.close-button::before,
.close-button::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 18px;
  top: 50%;
  left: 50%;
  background: #fff;
  transform: translate(-50%, -50%);
}

.close-button::before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

.close-button::after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* Menu */
.menu-wrap {
  position: fixed;
  z-index: 1001;
  width: 390px;
  height: 100vh;
  top: 0;
  right: 0;
  font-size: 1.15em;
  -webkit-transform: translate3d(390px, 0, 0);
  transform: translate3d(390px, 0, 0);
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

@media (max-width: 450px) {
  .menu-wrap {
    width: 320px;
    -webkit-transform: translate3d(320px, 0, 0);
    transform: translate3d(320px, 0, 0);
  }
}
.morph-shape svg {
  transform: rotate(180deg);
}

.menu {
  background: #fff;
  width: calc(100% - 40px);
  height: 100%;
  float: right;
  right: 0;
  top: 0;
}

/* Morph Shape */
.morph-shape {
  position: absolute;
  width: 40px;
  height: 100%;
  top: 0;
  left: 0;
  fill: #fff;
  z-index: 1000;
  background: #fff;
}

/* Shown menu */
.show-menu .menu-wrap {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  right: 0;
}

html {
  font-family: "Roboto", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
}

body {
  margin: 0;
  padding: 0;
  line-height: 1.5;
  font-size: 15px;
  font-family: "Roboto", sans-serif;
}

article,
aside,
footer,
header,
nav,
section {
  display: block;
}

figcaption,
figure,
main {
  display: block;
}

figure {
  margin: 1em 0;
}

hr {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace, monospace;
  font-size: 1em;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
  outline-width: 0;
}

abbr[title] {
  border-bottom: 1px #767676 dotted;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: 700;
}

code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #eee;
  color: #222;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

audio,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

img {
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Roboto", sans-serif;
  font-size: 100%;
  margin: 0;
  outline: none;
}

button,
input {
  overflow: visible;
  outline: none;
}

button,
select {
  text-transform: none;
  outline: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  border-style: none;
  padding: 0;
  outline: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: 1px dotted ButtonText;
  outline: none;
}

fieldset {
  border: 1px solid #bbb;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

progress {
  display: inline-block;
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
  outline: none;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  outline: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
  outline: none;
}

details,
menu {
  display: block;
}

summary {
  display: list-item;
}

canvas {
  display: inline-block;
}

template {
  display: none;
}

[hidden] {
  display: none;
}

/*--------------------------------------------------------------
2.0 Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
  /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
  /* Above WP toolbar. */
  outline: none;
}

/*--------------------------------------------------------------
3.0 Alignments
--------------------------------------------------------------*/
.alignleft {
  display: inline;
  float: left;
  margin-right: 1.5em;
}

.alignright {
  display: inline;
  float: right;
  margin-left: 1.5em;
}

.aligncenter {
  clear: both;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

/*--------------------------------------------------------------
4.0 Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after,
.nav-links:before,
.nav-links:after,
.pagination:before,
.pagination:after,
.comment-author:before,
.comment-author:after,
.widget-area:before,
.widget-area:after,
.widget:before,
.widget:after,
.comment-meta:before,
.comment-meta:after {
  content: "";
  display: table;
  table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after,
.nav-links:after,
.pagination:after,
.comment-author:after,
.widget-area:after,
.widget:after,
.comment-meta:after {
  clear: both;
}

/*--------------------------------------------------------------
5.0 Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
textarea {
  color: #696969;
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  margin: 0.25em 0 0.25em;
  font-weight: 600;
  color: #000;
  font-family: "Frank Ruhl Libre", serif;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  padding-top: 0;
}

h1 {
  font-size: 42px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 20px;
}

h5 {
  font-size: 18px;
}

h6 {
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  line-height: 1.5;
  font-family: "Frank Ruhl Libre", serif;
}

h2 > span {
  font-weight: 700;
  color: #ff4181;
}

h5 {
  font-weight: 400;
  font-family: "Frank Ruhl Libre", serif;
}

h5 > span {
  font-weight: 600;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: #000;
}

p {
  margin: 0 0 1.5em;
  padding: 0;
  font-size: 1em;
  color: #696969;
  font-family: "Roboto", sans-serif;
  line-height: 26px;
}

dfn,
cite,
em,
i {
  font-style: italic;
}

blockquote {
  padding: 50px 0px 0px 0px;
  margin: 20px 0;
  font-weight: 400;
  color: #fff;
  position: relative;
  font-style: italic;
}
@media (min-width: 576px) {
  blockquote {
    padding: 0px 0px 0px 55px;
  }
}
blockquote::before {
  left: 0;
  top: 7px;
  background: url(../../assets/images/icon/bc-icon.png);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
}
blockquote p {
  font-family: "Frank Ruhl Libre", serif;
  font-size: 17px;
  line-height: 30px;
  color: #000;
  margin-bottom: 0;
}

blockquote cite {
  display: block;
  font-style: normal;
  font-weight: 400;
  margin-top: 0.5em;
  font-size: 15px;
  color: #696969;
}

address {
  margin: 0 0 1.5em;
  line-height: 1.5;
}

pre {
  background: #eee;
  font-family: "Courier 10 Pitch", Courier, monospace;
  font-size: 1rem;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1.6em;
}

code,
kbd,
tt,
var {
  font-family: Monaco, Consolas, "Andale Mono", "DejaVu Sans Mono", monospace;
  font-size: 1rem;
}

abbr,
acronym {
  border-bottom: 1px dotted #666;
  cursor: help;
}

mark,
ins {
  background: #eee;
  text-decoration: none;
}

big {
  font-size: 125%;
}

:focus {
  outline: none;
}

/* Typography for Arabic Font */
html[lang=ar] body,
html[lang=ar] button,
html[lang=ar] input,
html[lang=ar] select,
html[lang=ar] textarea,
html[lang=ary] body,
html[lang=ary] button,
html[lang=ary] input,
html[lang=ary] select,
html[lang=ary] textarea,
html[lang=azb] body,
html[lang=azb] button,
html[lang=azb] input,
html[lang=azb] select,
html[lang=azb] textarea,
html[lang=fa-IR] body,
html[lang=fa-IR] button,
html[lang=fa-IR] input,
html[lang=fa-IR] select,
html[lang=fa-IR] textarea,
html[lang=haz] body,
html[lang=haz] button,
html[lang=haz] input,
html[lang=haz] select,
html[lang=haz] textarea,
html[lang=ps] body,
html[lang=ps] button,
html[lang=ps] input,
html[lang=ps] select,
html[lang=ps] textarea,
html[lang=ur] body,
html[lang=ur] button,
html[lang=ur] input,
html[lang=ur] select,
html[lang=ur] textarea {
  font-family: Tahoma, Arial, sans-serif;
  outline: none;
}

html[lang=ar] h1,
html[lang=ar] h2,
html[lang=ar] h3,
html[lang=ar] h4,
html[lang=ar] h5,
html[lang=ar] h6,
html[lang=ary] h1,
html[lang=ary] h2,
html[lang=ary] h3,
html[lang=ary] h4,
html[lang=ary] h5,
html[lang=ary] h6,
html[lang=azb] h1,
html[lang=azb] h2,
html[lang=azb] h3,
html[lang=azb] h4,
html[lang=azb] h5,
html[lang=azb] h6,
html[lang=fa-IR] h1,
html[lang=fa-IR] h2,
html[lang=fa-IR] h3,
html[lang=fa-IR] h4,
html[lang=fa-IR] h5,
html[lang=fa-IR] h6,
html[lang=haz] h1,
html[lang=haz] h2,
html[lang=haz] h3,
html[lang=haz] h4,
html[lang=haz] h5,
html[lang=haz] h6,
html[lang=ps] h1,
html[lang=ps] h2,
html[lang=ps] h3,
html[lang=ps] h4,
html[lang=ps] h5,
html[lang=ps] h6,
html[lang=ur] h1,
html[lang=ur] h2,
html[lang=ur] h3,
html[lang=ur] h4,
html[lang=ur] h5,
html[lang=ur] h6 {
  font-weight: 700;
}

/* Typography for Chinese Font */
html[lang^=zh-] body,
html[lang^=zh-] button,
html[lang^=zh-] input,
html[lang^=zh-] select,
html[lang^=zh-] textarea {
  font-family: "PingFang TC", "Helvetica Neue", Helvetica, STHeitiTC-Light, Arial, sans-serif;
}

html[lang=zh-CN] body,
html[lang=zh-CN] button,
html[lang=zh-CN] input,
html[lang=zh-CN] select,
html[lang=zh-CN] textarea {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, STHeitiSC-Light, Arial, sans-serif;
}

html[lang^=zh-] h1,
html[lang^=zh-] h2,
html[lang^=zh-] h3,
html[lang^=zh-] h4,
html[lang^=zh-] h5,
html[lang^=zh-] h6 {
  font-weight: 700;
}

/* Typography for Cyrillic Font */
html[lang=bg-BG] body,
html[lang=bg-BG] button,
html[lang=bg-BG] input,
html[lang=bg-BG] select,
html[lang=bg-BG] textarea,
html[lang=ru-RU] body,
html[lang=ru-RU] button,
html[lang=ru-RU] input,
html[lang=ru-RU] select,
html[lang=ru-RU] textarea,
html[lang=uk] body,
html[lang=uk] button,
html[lang=uk] input,
html[lang=uk] select,
html[lang=uk] textarea {
  font-family: "Helvetica Neue", Helvetica, "Segoe UI", Arial, sans-serif;
}

html[lang=bg-BG] h1,
html[lang=bg-BG] h2,
html[lang=bg-BG] h3,
html[lang=bg-BG] h4,
html[lang=bg-BG] h5,
html[lang=bg-BG] h6,
html[lang=ru-RU] h1,
html[lang=ru-RU] h2,
html[lang=ru-RU] h3,
html[lang=ru-RU] h4,
html[lang=ru-RU] h5,
html[lang=ru-RU] h6,
html[lang=uk] h1,
html[lang=uk] h2,
html[lang=uk] h3,
html[lang=uk] h4,
html[lang=uk] h5,
html[lang=uk] h6 {
  font-weight: 700;
}

/* Typography for Devanagari Font */
html[lang=bn-BD] body,
html[lang=bn-BD] button,
html[lang=bn-BD] input,
html[lang=bn-BD] select,
html[lang=bn-BD] textarea,
html[lang=hi-IN] body,
html[lang=hi-IN] button,
html[lang=hi-IN] input,
html[lang=hi-IN] select,
html[lang=hi-IN] textarea,
html[lang=mr-IN] body,
html[lang=mr-IN] button,
html[lang=mr-IN] input,
html[lang=mr-IN] select,
html[lang=mr-IN] textarea {
  font-family: Arial, sans-serif;
}

html[lang=bn-BD] h1,
html[lang=bn-BD] h2,
html[lang=bn-BD] h3,
html[lang=bn-BD] h4,
html[lang=bn-BD] h5,
html[lang=bn-BD] h6,
html[lang=hi-IN] h1,
html[lang=hi-IN] h2,
html[lang=hi-IN] h3,
html[lang=hi-IN] h4,
html[lang=hi-IN] h5,
html[lang=hi-IN] h6,
html[lang=mr-IN] h1,
html[lang=mr-IN] h2,
html[lang=mr-IN] h3,
html[lang=mr-IN] h4,
html[lang=mr-IN] h5,
html[lang=mr-IN] h6 {
  font-weight: 700;
}

/* Typography for Greek Font */
html[lang=el] body,
html[lang=el] button,
html[lang=el] input,
html[lang=el] select,
html[lang=el] textarea {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html[lang=el] h1,
html[lang=el] h2,
html[lang=el] h3,
html[lang=el] h4,
html[lang=el] h5,
html[lang=el] h6 {
  font-weight: 700;
}

/* Typography for Gujarati Font */
html[lang=gu-IN] body,
html[lang=gu-IN] button,
html[lang=gu-IN] input,
html[lang=gu-IN] select,
html[lang=gu-IN] textarea {
  font-family: Arial, sans-serif;
}

html[lang=gu-IN] h1,
html[lang=gu-IN] h2,
html[lang=gu-IN] h3,
html[lang=gu-IN] h4,
html[lang=gu-IN] h5,
html[lang=gu-IN] h6 {
  font-weight: 700;
}

/* Typography for Hebrew Font */
html[lang=he-IL] body,
html[lang=he-IL] button,
html[lang=he-IL] input,
html[lang=he-IL] select,
html[lang=he-IL] textarea {
  font-family: "Arial Hebrew", Arial, sans-serif;
}

html[lang=he-IL] h1,
html[lang=he-IL] h2,
html[lang=he-IL] h3,
html[lang=he-IL] h4,
html[lang=he-IL] h5,
html[lang=he-IL] h6 {
  font-weight: 700;
}

/* Typography for Japanese Font */
html[lang=ja] body,
html[lang=ja] button,
html[lang=ja] input,
html[lang=ja] select,
html[lang=ja] textarea {
  font-family: "Hiragino Kaku Gothic Pro", Meiryo, sans-serif;
}

html[lang=ja] h1,
html[lang=ja] h2,
html[lang=ja] h3,
html[lang=ja] h4,
html[lang=ja] h5,
html[lang=ja] h6 {
  font-weight: 700;
}

/* Typography for Korean font */
html[lang=ko-KR] body,
html[lang=ko-KR] button,
html[lang=ko-KR] input,
html[lang=ko-KR] select,
html[lang=ko-KR] textarea {
  font-family: "Apple SD Gothic Neo", "Malgun Gothic", "Nanum Gothic", Dotum, sans-serif;
}

html[lang=ko-KR] h1,
html[lang=ko-KR] h2,
html[lang=ko-KR] h3,
html[lang=ko-KR] h4,
html[lang=ko-KR] h5,
html[lang=ko-KR] h6 {
  font-weight: 600;
}

/* Typography for Thai Font */
html[lang=th] h1,
html[lang=th] h2,
html[lang=th] h3,
html[lang=th] h4,
html[lang=th] h5,
html[lang=th] h6 {
  font-family: "Sukhumvit Set", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

html[lang=th] body,
html[lang=th] button,
html[lang=th] input,
html[lang=th] select,
html[lang=th] textarea {
  font-family: "Sukhumvit Set", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Remove letter-spacing for all non-latin alphabets */
html[lang=ar] *,
html[lang=ary] *,
html[lang=azb] *,
html[lang=haz] *,
html[lang=ps] *,
html[lang^=zh-] *,
html[lang=bg-BG] *,
html[lang=ru-RU] *,
html[lang=uk] *,
html[lang=bn-BD] *,
html[lang=hi-IN] *,
html[lang=mr-IN] *,
html[lang=el] *,
html[lang=gu-IN] *,
html[lang=he-IL] *,
html[lang=ja] *,
html[lang=ko-KR] *,
html[lang=th] * {
  letter-spacing: 0 !important;
}

/*--------------------------------------------------------------
6.0 Forms
--------------------------------------------------------------*/
label {
  color: #696969;
  display: block;
  font-weight: 800;
  margin-bottom: 0.5em;
}

fieldset {
  margin-bottom: 1em;
}

input[type=text],
input[type=email],
input[type=url],
input[type=password],
input[type=search],
input[type=number],
input[type=tel],
input[type=range],
input[type=date],
input[type=month],
input[type=week],
input[type=time],
input[type=datetime],
input[type=datetime-local],
input[type=color],
textarea {
  color: #666;
  background: #fff;
  background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
  border: 1px solid #ececec;
  display: block;
  padding: 14px 20px;
  width: 100%;
  margin-bottom: 20px;
  outline: none;
}

input[type=text]:focus,
input[type=email]:focus,
input[type=url]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=number]:focus,
input[type=tel]:focus,
input[type=range]:focus,
input[type=date]:focus,
input[type=month]:focus,
input[type=week]:focus,
input[type=time]:focus,
input[type=datetime]:focus,
input[type=datetime-local]:focus,
input[type=color]:focus,
textarea:focus {
  color: #222;
  border-color: #696969;
  outline: none;
}

select {
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  border: 1px solid #ededed;
  border-radius: 50px;
  margin-bottom: 1.25rem;
  -webkit-appearance: none;
}

input[type=radio],
input[type=checkbox] {
  margin-right: 0.5em;
}

input[type=radio] + label,
input[type=checkbox] + label {
  font-weight: 400;
}

input + button,
input + input[type=button],
input + input[type=submit] {
  padding: 0.75em 2em;
}

button.secondary,
input[type=reset],
input[type=button].secondary,
input[type=reset].secondary,
input[type=submit].secondary {
  background-color: #ddd;
  color: #222;
}

button:hover,
button:focus,
input[type=button]:hover,
input[type=button]:focus,
input[type=submit]:hover,
input[type=submit]:focus {
  background: #767676;
  outline: none;
}

button.secondary:hover,
button.secondary:focus,
input[type=reset]:hover,
input[type=reset]:focus,
input[type=button].secondary:hover,
input[type=button].secondary:focus,
input[type=reset].secondary:hover,
input[type=reset].secondary:focus,
input[type=submit].secondary:hover,
input[type=submit].secondary:focus {
  background: #bbb;
  outline: none;
}

.comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 40px;
}
.comment-form input[type=text] {
  padding: 15px;
  padding-left: 20px;
  border: 1px solid #ececec;
  margin-bottom: 30px;
}
.comment-form input[type=email] {
  padding: 15px;
  padding-left: 20px;
  border: 1px solid #ececec;
  border-radius: 50px;
}
.comment-form input[type=url] {
  padding: 15px;
  padding-left: 20px;
  border: 0;
  background: #f4f9fd;
}
.comment-form input[type=submit] {
  background: #fed643;
  margin-top: 10px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}
.comment-form textarea {
  margin-bottom: 30px;
  border: 1px solid #ececec;
  padding: 20px;
  background: #f1f6fa;
}

/* Placeholder text color -- selectors need to be separate to work. */
::-webkit-input-placeholder {
  color: #696969;
  font-family: "Roboto", sans-serif;
}

:-moz-placeholder {
  color: #696969;
}

::-moz-placeholder {
  color: #696969;
  opacity: 1;
  /* Since FF19 lowers the opacity of the placeholder by default */
}

::-ms-input-placeholder {
  color: #696969;
}

/*--------------------------------------------------------------
7.0 Formatting
--------------------------------------------------------------*/
hr {
  background-color: #bbb;
  border: 0;
  height: 1px;
  margin-bottom: 1.5em;
}

/*--------------------------------------------------------------
8.0 Lists
--------------------------------------------------------------*/
ul,
ol {
  padding: 0;
}

ul {
  list-style: disc;
  margin-left: 1.5em;
}

ol {
  list-style: decimal;
  margin-left: 1.5em;
}

li > ul,
li > ol {
  margin-left: 1.5em;
}

dt {
  font-weight: 700;
  line-height: 1.5;
  margin: 0 1.5em;
}

dd {
  margin: 0 1.5em 1.5em;
  line-height: 1.5;
}

li {
  list-style: none;
}

a {
  outline: none;
}
a:hover {
  text-decoration: none;
}

/*--------------------------------------------------------------
9.0 Tables
--------------------------------------------------------------*/
table {
  border-collapse: collapse;
  margin: 0 0 1.5em;
  width: 100%;
}

thead th {
  padding-bottom: 0.5em;
}

th {
  padding: 0.4em;
  text-align: left;
  border: 1px solid #ececec;
}

tr {
  border-bottom: 1px solid #ececec;
  border-top: 1px solid #ececec;
  border-left: 1px solid #ececec;
}

td {
  padding: 1em;
  border: 1px solid #ececec;
}

.breadcrumb {
  padding: 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
  margin: 0;
  display: flex;
}

th:last-child,
td:last-child {
  padding-right: 0;
}

/*--------------------------------------------------------------
10.0 Links
--------------------------------------------------------------*/
a {
  color: #696969;
  text-decoration: none;
  display: inline-block;
  outline: none;
}

a:focus {
  outline: none;
}

a:hover,
a:active {
  color: #000;
  outline: 0;
}

/* Hover effects */
.entry-summary a,
.widget a,
.site-footer .widget-area a,
.posts-navigation a,
.widget_authors a strong {
  -webkit-box-shadow: inset 0 -1px 0 #0f0f0f;
  box-shadow: inset 0 -1px 0 #0f0f0f;
  -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
}

.entry-title a,
.entry-meta a,
.page-links a,
.page-links a .page-number,
.edit-link a,
.post-navigation a,
.logged-in-as a,
.comment-navigation a,
.comment-reply-link,
a .nav-title,
.site-info a,
.widget .widget-title a,
.widget ul li a,
.site-footer .widget-area ul li a,
.site-footer .widget-area ul li a {
  -webkit-box-shadow: inset 0 -1px 0 white;
  box-shadow: inset 0 -1px 0 white;
  text-decoration: none;
  -webkit-transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, -webkit-box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out;
  transition: color 80ms ease-in, box-shadow 130ms ease-in-out, -webkit-box-shadow 130ms ease-in-out;
}

.entry-summary a:focus,
.entry-summary a:hover,
.widget a:focus,
.widget a:hover,
.site-footer .widget-area a:focus,
.site-footer .widget-area a:hover,
.posts-navigation a:focus,
.posts-navigation a:hover,
.comment-reply-link:focus,
.comment-reply-link:hover,
.widget_authors a:focus strong,
.widget_authors a:hover strong,
.entry-title a:focus,
.entry-title a:hover,
.entry-meta a:focus,
.entry-meta a:hover,
.page-links a:focus .page-number,
.page-links a:hover .page-number,
.post-navigation a:focus,
.post-navigation a:hover,
.logged-in-as a:focus,
.logged-in-as a:hover,
a:focus .nav-title,
a:hover .nav-title,
.edit-link a:focus,
.edit-link a:hover,
.site-info a:focus,
.site-info a:hover,
.widget .widget-title a:focus,
.widget .widget-title a:hover,
.widget ul li a:focus,
.widget ul li a:hover {
  color: #000;
  outline: none;
}

/* Fixes linked images */
.entry-content a img,
.comment-content a img,
.widget a img {
  -webkit-box-shadow: 0 0 0 8px #fff;
  box-shadow: 0 0 0 8px #fff;
}

.post-navigation a:focus .icon,
.post-navigation a:hover .icon {
  color: #222;
}

/*--------------------------------------------------------------
11.0 Featured Image Hover
--------------------------------------------------------------*/
.post-thumbnail {
  margin-bottom: 1em;
}

.post-thumbnail a img {
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
}

.post-thumbnail a:hover img,
.post-thumbnail a:focus img {
  opacity: 0.7;
  outline: none;
}

/*--------------------------------------------------------------
13.0 Layout
--------------------------------------------------------------*/
html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

#page {
  position: relative;
  word-wrap: break-word;
}

.wrap {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  padding-left: 2em;
  padding-right: 2em;
}

.wrap:after {
  clear: both;
  content: "";
  display: block;
}

/*--------------------------------------------------------------
13.4 Posts
--------------------------------------------------------------*/
/* Post Landing Page */
.sticky {
  position: relative;
}

.post:not(.sticky) .icon-thumb-tack {
  display: none;
}

.sticky .icon-thumb-tack {
  display: block;
  height: 18px;
  left: -1.5em;
  position: absolute;
  top: 1.65em;
  width: 20px;
}

.page .panel-content .entry-title,
.page-title,
body.page:not(.twentyseventeen-front-page) .entry-title {
  font-size: 1rem;
  font-weight: 400;
}

.entry-header .entry-title {
  margin-bottom: 0.25em;
}

.entry-title a {
  color: #696969;
  text-decoration: none;
  margin-left: -2px;
}

.entry-title:not(:first-child) {
  padding-top: 0;
}

.entry-meta a {
  color: #767676;
}

.single .byline,
.group-blog .byline {
  display: inline;
}

.page-numbers {
  display: none;
  padding: 0.5em 0.75em;
}

.page-numbers.current {
  color: #767676;
  display: inline-block;
}

.page-numbers.current .screen-reader-text {
  clip: auto;
  height: auto;
  overflow: auto;
  position: relative !important;
  width: auto;
}

.prev.page-numbers,
.next.page-numbers {
  background-color: #ddd;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  display: inline-block;
  padding: 0.25em 0.5em 0.4em;
}

.prev.page-numbers,
.next.page-numbers {
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.prev.page-numbers:focus,
.prev.page-numbers:hover,
.next.page-numbers:focus,
.next.page-numbers:hover {
  background-color: #767676;
  color: #fff;
}

.prev.page-numbers {
  float: left;
}

.next.page-numbers {
  float: right;
}

/* Aligned blockquotes */
.entry-content blockquote.alignleft,
.entry-content blockquote.alignright {
  color: #666;
  font-size: 1rem;
  width: 48%;
}

/* Blog landing, search, archives */
.blog .site-main > article,
.archive .site-main > article,
.search .site-main > article {
  padding-bottom: 2em;
}

.blog .entry-meta a.post-edit-link,
.archive .entry-meta a.post-edit-link,
.search .entry-meta a.post-edit-link {
  color: #222;
  display: inline-block;
  margin-left: 1em;
  white-space: nowrap;
}

.search .page .entry-meta a.post-edit-link {
  margin-left: 0;
  white-space: nowrap;
}

.taxonomy-description {
  color: #666;
  font-size: 1rem;
}

/* More tag */
.entry-content .more-link:before {
  content: "";
  display: block;
  margin-top: 1.5em;
}

/* Single Post */
.single-featured-image-header {
  background-color: #fafafa;
  border-bottom: 1px solid #eee;
}

.single-featured-image-header img {
  display: block;
  margin: auto;
}

.page-links {
  font-size: 1rem;
  padding: 2em 0 3em;
}

.page-links .page-number {
  color: #767676;
  display: inline-block;
  padding: 0.5em 1em;
}

.page-links a {
  display: inline-block;
}

.page-links a .page-number {
  color: #222;
}

/* Post Formats */
.blog .format-status .entry-title,
.archive .format-status .entry-title,
.blog .format-aside .entry-title,
.archive .format-aside .entry-title {
  display: none;
}

.format-quote blockquote {
  overflow: visible;
  position: relative;
}

.format-quote blockquote .icon {
  display: block;
  height: 20px;
  left: -1.25em;
  position: absolute;
  top: 0.4em;
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
  width: 20px;
}

/* Post Navigation */
.post-navigation {
  font-weight: 800;
  margin: 3em 0;
}

.post-navigation .nav-links {
  padding: 1em 0;
}

.nav-subtitle {
  background: transparent;
  display: block;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.post-navigation .nav-next {
  margin-top: 1.5em;
}

.nav-links .nav-previous .nav-title .nav-title-icon-wrapper {
  margin-right: 0.5em;
}

.nav-links .nav-next .nav-title .nav-title-icon-wrapper {
  margin-left: 0.5em;
}

/*--------------------------------------------------------------
13.5 Pages
--------------------------------------------------------------*/
.page-links {
  clear: both;
  margin: 0 0 1.5em;
}

.page:not(.home) #content {
  padding-bottom: 1.5em;
}

/* 404 page */
.error404 .page-content {
  padding-bottom: 4em;
}

.error404 .page-content .search-form,
.search .page-content .search-form {
  margin-bottom: 3em;
}

/*--------------------------------------------------------------
13.6 Footer
--------------------------------------------------------------*/
.site-footer {
  border-top: 1px solid #eee;
}

.site-footer .wrap {
  padding-bottom: 1.5em;
  padding-top: 2em;
}

/* Footer widgets */
.site-footer .widget-area {
  padding-bottom: 2em;
  padding-top: 2em;
}

/* Social nav */
.social-navigation {
  font-size: 1rem;
  margin-bottom: 1em;
}

.social-navigation ul {
  list-style: none;
  margin-bottom: 0;
  margin-left: 0;
}

.social-navigation li {
  display: inline;
}

.social-navigation a {
  background-color: #767676;
  -webkit-border-radius: 40px;
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  height: 40px;
  margin: 0 1em 0.5em 0;
  text-align: center;
  width: 40px;
}

.social-navigation a:hover,
.social-navigation a:focus {
  background-color: #696969;
  outline: none;
}

.social-navigation .icon {
  height: 16px;
  top: 12px;
  width: 16px;
  vertical-align: top;
}

/* Site info */
.site-info {
  font-size: 1rem;
  margin-bottom: 1em;
}

.site-info a {
  color: #666;
}

.site-info .sep {
  margin: 0;
  display: block;
  visibility: hidden;
  height: 0;
  width: 100%;
}

.site-info span[role=separator] {
  padding: 0 0.2em 0 0.4em;
}

.site-info span[role=separator]::before {
  content: "/";
}

/*--------------------------------------------------------------
14.0 Comments
--------------------------------------------------------------*/
#comments {
  clear: both;
}

.comments-title {
  margin-bottom: 1.5em;
}

.comment-list,
.comment-list .children {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 20px;
}

.comment-list li:before {
  display: none;
}

.comment-author {
  font-size: 1rem;
  margin-bottom: 0.4em;
  position: relative;
  z-index: 2;
}

.comment-author .says {
  display: none;
}

.comment-meta {
  margin-bottom: 1rem;
}

.comment-list > .comment:first-child {
  border-top: none;
}

.comment {
  border-top: 1px solid #ececec;
  padding-top: 30px;
}
.comment:first-child {
  margin-top: 0;
}
.comment .comment-body {
  border-top: 1px solid #ececec;
}
.comment .comment-body:first-child {
  border-top: none;
}

.comment-metadata {
  font-size: 15px;
  text-transform: capitalize;
  font-weight: 400;
  width: 100%;
}
@media (min-width: 992px) {
  .comment-metadata {
    padding-left: 20px;
  }
}
.comment-metadata .fn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
}
.comment-metadata .fn a {
  color: #696969;
  font-family: "Roboto", sans-serif;
  outline: none;
  display: block;
  font-size: 1rem;
  box-shadow: none;
}
.comment-metadata .fn .url {
  font-weight: 700;
}
.comment-metadata time {
  font-weight: 400;
  font-size: 0.875rem;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.comment-metadata a.comment-edit-link {
  color: #222;
  margin-left: 1em;
}

.comment-body {
  color: #696969;
  font-size: 1rem;
}

.comment-reply-link {
  position: relative;
  text-transform: uppercase;
  color: #ff4181 !important;
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.comment-reply-link:hover {
  color: #fed643 !important;
}

.comment-reply-link .icon {
  color: #222;
  left: -2em;
  height: 1em;
  position: absolute;
  top: 0;
  width: 1em;
}

.no-comments,
.comment-awaiting-moderation {
  font-size: 1rem;
  font-style: italic;
}

.comments-pagination {
  margin: 2em 0 3em;
}

.form-submit {
  text-align: right;
}

.comment-form #wp-comment-cookies-consent {
  margin: 0 10px 0 0;
}

.comment-form .comment-form-cookies-consent label {
  display: inline;
}

/*--------------------------------------------------------------
15.0 Widgets
--------------------------------------------------------------*/
#secondary {
  padding: 1em 0 2em;
}

.widget {
  padding-bottom: 3em;
}

h2.widget-title {
  font-size: 1rem;
  margin-bottom: 1.5em;
}

.widget-title a {
  color: inherit;
}

/* widget forms */
.widget select {
  width: 100%;
}

/* widget lists */
.widget ul {
  list-style: none;
  margin: 0;
}

li {
  padding: 5px 0;
}

.widget ul li,
.widget ol li {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
  padding: 0.5em 0;
}

.widget:not(.widget_tag_cloud) ul li + li {
  margin-top: -1px;
}

.widget ul li ul {
  margin: 0 0 -1px;
  padding: 0;
  position: relative;
}

.widget ul li li {
  border: 0;
  padding-left: 24px;
  padding-left: 1.5rem;
}

/* Widget lists of links */
.widget_top-posts ul li ul,
.widget_rss_links ul li ul,
.widget-grofile ul.grofile-links li ul,
.widget_pages ul li ul,
.widget_meta ul li ul {
  bottom: 0;
}

.widget_nav_menu ul li li,
.widget_top-posts ul li,
.widget_top-posts ul li li,
.widget_rss_links ul li,
.widget_rss_links ul li li,
.widget-grofile ul.grofile-links li,
.widget-grofile ul.grofile-links li li {
  padding-bottom: 0.25em;
  padding-top: 0.25em;
}

.widget_rss ul li {
  padding-bottom: 1em;
  padding-top: 1em;
}

/* Text widget */
.widget_text {
  word-wrap: break-word;
}

.widget_text ul {
  list-style: disc;
  margin: 0 0 1.5em 1.5em;
}

.widget_text ol {
  list-style: decimal;
}

.widget_text ul li,
.widget_text ol li {
  border: none;
}

.widget_text ul li:last-child,
.widget_text ol li:last-child {
  padding-bottom: 0;
}

.widget_text ul li ul {
  margin: 0 0 0 1.5em;
}

.widget_text ul li li {
  padding-left: 0;
  padding-right: 0;
}

.widget_text ol li {
  list-style-position: inside;
}

.widget_text ol li + li {
  margin-top: -1px;
}

/* RSS Widget */
.widget_rss .widget-title .rsswidget:first-child {
  float: right;
}

.widget_rss .widget-title .rsswidget:first-child:hover {
  background-color: transparent;
}

.widget_rss .widget-title .rsswidget:first-child img {
  display: block;
}

.widget_rss ul li {
  padding: 2.125em 0;
}

.widget_rss ul li:first-child {
  border-top: none;
  padding-top: 0;
}

.widget_rss li .rsswidget {
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: 300;
}

.widget_rss .rss-date,
.widget_rss li cite {
  display: block;
  font-size: 10px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0.18em;
  text-transform: uppercase;
}

.widget_rss .rss-date {
  margin: 0.5em 0 1.5em;
  padding: 0;
}

.widget_rss .rssSummary {
  margin-bottom: 0.5em;
}

/* Contact Info Widget */
.widget_contact_info .contact-map {
  margin-bottom: 0.5em;
}

/* Gravatar */
.widget-grofile h4 {
  font-size: 1rem;
  margin-bottom: 0;
}

/* Recent Comments */
.widget_recent_comments table,
.widget_recent_comments th,
.widget_recent_comments td {
  border: 0;
}

/* Recent Posts widget */
.widget_recent_entries .post-date {
  display: block;
}

/* Search */
.search-form {
  position: relative;
}

.search-form .search-submit {
  bottom: 3px;
  padding: 0.5em 1em;
  position: absolute;
  right: 3px;
  top: 3px;
}

.search-form .search-submit .icon {
  height: 24px;
  top: -2px;
  width: 24px;
}

/* Tag cloud widget */
.tagcloud ul li {
  float: left;
  border-top: 0;
  border-bottom: 0;
  padding: 0;
  margin: 4px 4px 0 0;
}

.widget .tagcloud a,
.widget.widget_tag_cloud a,
.wp_widget_tag_cloud a {
  border: 1px solid #ddd;
  -webkit-box-shadow: none;
  box-shadow: none;
  display: block;
  padding: 4px 10px 5px;
  position: relative;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  width: auto;
  word-wrap: break-word;
  z-index: 0;
}

.widget .tagcloud a:hover,
.widget .tagcloud a:focus,
.widget.widget_tag_cloud a:hover,
.widget.widget_tag_cloud a:focus,
.wp_widget_tag_cloud a:hover,
.wp_widget_tag_cloud a:focus {
  border-color: #bbb;
  -webkit-box-shadow: none;
  box-shadow: none;
  text-decoration: none;
  outline: none;
}

/* Calendar widget */
.widget_calendar th,
.widget_calendar td {
  text-align: center;
}

.widget_calendar tfoot td {
  border: 0;
}

/* Gallery widget */
.gallery-columns-5 .gallery-caption,
.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

/*--------------------------------------------------------------
16.0 Media
--------------------------------------------------------------*/
img,
video {
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

img.alignleft,
img.alignright {
  float: none;
  margin: 0;
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
  border: none;
  margin-bottom: 0;
  margin-top: 0;
  padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
  margin-bottom: 1.5em;
  max-width: 100%;
}

/* Remove bottom on embeds that wrapped in paragraphs via wpautop. */
p > embed:only-child,
p > iframe:only-child,
p > object:only-child {
  margin-bottom: 0;
}

.wp-caption,
.gallery-caption {
  font-size: 1rem;
  font-style: italic;
  margin-bottom: 1.5em;
  max-width: 100%;
}

.wp-caption img[class*=wp-image-] {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption .wp-caption-text {
  margin: 0.8075em 0;
}

/* Media Elements */
.mejs-container {
  margin-bottom: 1.5em;
}

/* Audio Player */
.mejs-controls a.mejs-horizontal-volume-slider,
.mejs-controls a.mejs-horizontal-volume-slider:focus,
.mejs-controls a.mejs-horizontal-volume-slider:hover {
  background: transparent;
  border: 0;
  outline: none;
}

/* Playlist Color Overrides: Light */
.site-content .wp-playlist-light {
  border-color: #eee;
  color: #222;
}

.site-content .wp-playlist-light .wp-playlist-current-item .wp-playlist-item-album {
  color: #696969;
}

.site-content .wp-playlist-light .wp-playlist-current-item .wp-playlist-item-artist {
  color: #767676;
}

.site-content .wp-playlist-light .wp-playlist-item {
  border-bottom: 1px dotted #eee;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.site-content .wp-playlist-light .wp-playlist-item:hover,
.site-content .wp-playlist-light .wp-playlist-item:focus {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #767676;
  color: #fff;
  outline: none;
}

.site-content .wp-playlist-light a.wp-playlist-caption:hover,
.site-content .wp-playlist-light .wp-playlist-item:hover a,
.site-content .wp-playlist-light .wp-playlist-item:focus a {
  color: #fff;
  outline: none;
}

/* Playlist Color Overrides: Dark */
.site-content .wp-playlist-dark {
  background: #222;
  border-color: #696969;
}

.site-content .wp-playlist-dark .mejs-container .mejs-controls {
  background-color: #696969;
}

.site-content .wp-playlist-dark .wp-playlist-caption {
  color: #fff;
}

.site-content .wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-album {
  color: #eee;
}

.site-content .wp-playlist-dark .wp-playlist-current-item .wp-playlist-item-artist {
  color: #aaa;
}

.site-content .wp-playlist-dark .wp-playlist-playing {
  background-color: #696969;
}

.site-content .wp-playlist-dark .wp-playlist-item {
  border-bottom: 1px dotted #555;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, color 0.3s ease-in-out;
}

.site-content .wp-playlist-dark .wp-playlist-item:hover,
.site-content .wp-playlist-dark .wp-playlist-item:focus {
  border-bottom-color: rgba(0, 0, 0, 0);
  background-color: #aaa;
  color: #222;
  outline: none;
}

.site-content .wp-playlist-dark a.wp-playlist-caption:hover,
.site-content .wp-playlist-dark .wp-playlist-item:hover a,
.site-content .wp-playlist-dark .wp-playlist-item:focus a {
  color: #222;
  outline: none;
}

/* Playlist Style Overrides */
.site-content .wp-playlist {
  padding: 0.625em 0.625em 0.3125em;
}

.site-content .wp-playlist-current-item .wp-playlist-item-title {
  font-weight: 700;
}

.site-content .wp-playlist-current-item .wp-playlist-item-album {
  font-style: normal;
}

.site-content .wp-playlist-current-item .wp-playlist-item-artist {
  font-size: 10px;
  font-weight: 800;
  letter-spacing: 0.1818em;
  text-transform: uppercase;
}

.site-content .wp-playlist-item {
  padding: 0 0.3125em;
  cursor: pointer;
}

.site-content .wp-playlist-item:last-of-type {
  border-bottom: none;
}

.site-content .wp-playlist-item a {
  padding: 0.3125em 0;
  border-bottom: none;
}

.site-content .wp-playlist-item a,
.site-content .wp-playlist-item a:focus,
.site-content .wp-playlist-item a:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: transparent;
  outline: none;
}

.site-content .wp-playlist-item-length {
  top: 5px;
}

/* SVG Icons base styles */
.icon {
  display: inline-block;
  fill: currentColor;
  height: 1em;
  position: relative;
  /* Align more nicely with capital letters */
  top: -0.0625em;
  vertical-align: middle;
  width: 1em;
}

/*--------------------------------------------------------------
16.1 Galleries
--------------------------------------------------------------*/
.gallery-item {
  display: inline-block;
  text-align: left;
  vertical-align: top;
  margin: 0 0 1.5em;
  padding: 0 1em 0 0;
  width: 50%;
}

.gallery-columns-1 .gallery-item {
  width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-item a,
.gallery-item a:hover,
.gallery-item a:focus,
.widget-area .gallery-item a,
.widget-area .gallery-item a:hover,
.widget-area .gallery-item a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: none;
  display: inline-block;
  max-width: 100%;
  outline: none;
}

.gallery-item a img {
  display: block;
  -webkit-transition: -webkit-filter 0.2s ease-in;
  transition: -webkit-filter 0.2s ease-in;
  transition: filter 0.2s ease-in;
  transition: filter 0.2s ease-in, -webkit-filter 0.2s ease-in;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.gallery-item a:hover img,
.gallery-item a:focus img {
  -webkit-filter: opacity(60%);
  filter: opacity(60%);
  outline: none;
}

.gallery-caption {
  display: block;
  text-align: left;
  padding: 0 10px 0 0;
  margin-bottom: 0;
}

/*--------------------------------------------------------------
19.0 Media Queries
--------------------------------------------------------------*/
/* Adjust positioning of edit shortcuts, override style in customize-preview.css */
@media screen and (min-width: 576px) {
  /* Typography */
  body,
button,
input,
select,
textarea {
    font-size: 1rem;
    outline: none;
  }

  h1 {
    font-size: 60px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  .entry-content blockquote.alignleft,
.entry-content blockquote.alignright {
    font-size: 14px;
    font-size: 0.875rem;
  }

  /* Fix image alignment */
  img.alignleft {
    float: left;
    margin-right: 1.5em;
  }

  img.alignright {
    float: right;
    margin-left: 1.5em;
  }

  /* Site Branding */
  .site-branding {
    padding: 3em 0;
  }

  /* Front Page */
  .panel-content .wrap {
    padding-bottom: 2em;
    padding-top: 3.5em;
  }

  .page-one-column .panel-content .wrap {
    max-width: 740px;
  }

  .panel-content .entry-header {
    margin-bottom: 4.5em;
  }

  .panel-content .recent-posts .entry-header {
    margin-bottom: 0;
  }

  /* Blog Index, Archive, Search */
  .taxonomy-description {
    font-size: 14px;
    font-size: 0.875rem;
  }

  .page-numbers.current {
    font-size: 16px;
    font-size: 1rem;
  }

  /* Site Footer */
  .site-footer {
    font-size: 16px;
    font-size: 1rem;
  }

  /* Gallery Columns */
  .gallery-item {
    max-width: 25%;
  }

  .gallery-columns-1 .gallery-item {
    max-width: 100%;
  }

  .gallery-columns-2 .gallery-item {
    max-width: 50%;
  }

  .gallery-columns-3 .gallery-item {
    max-width: 33.33%;
  }

  .gallery-columns-4 .gallery-item {
    max-width: 25%;
  }
}
@media screen and (min-width: 768px) {
  .entry-content blockquote.alignleft,
.entry-content blockquote.alignright {
    font-size: 13px;
    font-size: 0.8125rem;
  }

  /* Layout */
  .wrap {
    max-width: 1000px;
    padding-left: 3em;
    padding-right: 3em;
  }

  .comments-pagination,
.post-navigation {
    clear: both;
  }

  .post-navigation .nav-previous {
    float: left;
    width: 50%;
  }

  .post-navigation .nav-next {
    float: right;
    text-align: right;
    width: 50%;
  }

  .nav-next,
.post-navigation .nav-next {
    margin-top: 0;
  }

  /* Blog, archive, search */
  .sticky .icon-thumb-tack {
    height: 23px;
    left: -2.5em;
    top: 1.5em;
    width: 32px;
  }

  .navigation.pagination {
    clear: both;
    float: right;
    width: 58%;
  }

  /* Entry content */
  /* without sidebar */
  :not(.has-sidebar) .entry-content blockquote.alignleft {
    margin-left: -17.5%;
    width: 48%;
  }

  :not(.has-sidebar) .entry-content blockquote.alignright {
    margin-right: -17.5%;
    width: 48%;
  }

  /* with sidebar */
  .has-sidebar .entry-content blockquote.alignleft {
    margin-left: 0;
    width: 34%;
  }

  .has-sidebar .entry-content blockquote.alignright {
    margin-right: 0;
    width: 34%;
  }

  .has-sidebar #primary .entry-content blockquote.alignright.below-entry-meta {
    margin-right: -72.5%;
    width: 62%;
  }

  /* Comments */
  #comments {
    padding: 25px 30px;
  }

  .comments-title {
    margin-bottom: 2.5em;
  }

  ol.children .children {
    padding-left: 3em;
  }

  /* Posts pagination */
  .nav-links .nav-title {
    position: relative;
  }

  .nav-title-icon-wrapper {
    position: absolute;
    text-align: center;
    width: 2em;
  }

  .nav-links .nav-previous .nav-title .nav-title-icon-wrapper {
    left: -2em;
  }

  .nav-links .nav-next .nav-title .nav-title-icon-wrapper {
    right: -2em;
  }

  /* Secondary */
  #secondary {
    font-size: 14px;
    font-size: 0.875rem;
  }

  /* Widgets */
  h2.widget-title {
    font-size: 11px;
    font-size: 0.6875rem;
    margin-bottom: 2em;
  }

  /* Footer */
  .site-footer {
    font-size: 14px;
    font-size: 0.875rem;
    margin-top: 3em;
  }

  .site-footer .widget-column.footer-widget-1 {
    float: left;
    width: 36%;
  }

  .site-footer .widget-column.footer-widget-2 {
    float: right;
    width: 58%;
  }

  .social-navigation {
    clear: left;
    float: left;
    margin-bottom: 0;
    width: 36%;
  }

  .site-info {
    float: left;
    padding: 0.7em 0 0;
    width: 58%;
  }

  .social-navigation + .site-info {
    margin-left: 6%;
  }

  .site-info .sep {
    margin: 0 0.5em;
    display: inline;
    visibility: visible;
    height: auto;
    width: auto;
  }

  /* Gallery Columns */
  .gallery-columns-5 .gallery-item {
    max-width: 20%;
  }

  .gallery-columns-6 .gallery-item {
    max-width: 16.66%;
  }

  .gallery-columns-7 .gallery-item {
    max-width: 14.28%;
  }

  .gallery-columns-8 .gallery-item {
    max-width: 12.5%;
  }

  .gallery-columns-9 .gallery-item {
    max-width: 11.11%;
  }
}
@media screen and (min-width: 992px) {
  /* Pagination */
  .page-numbers {
    display: inline-block;
  }

  .page-numbers.current {
    font-size: 15px;
    font-size: 0.9375rem;
  }

  .page-numbers.current .screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
  }

  /* Comments */
  .comment-meta {
    display: flex;
    align-items: center;
  }
}

