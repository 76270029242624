/* @@@@@@@@@@ global css start here @@@@@@ */
.padding-tb {
	padding: 60px 0;
	@include breakpoint(lg) {
    	padding: 100px 0;
    }
}
.padding-60 {
    padding: 60px 0;
}
.padding-30 {
    padding: 30px;
}

// section heading start here
.section-header {
    max-width: 800px;
    margin-bottom: 45px !important;
    @include breakpoint(xl) {
        margin: 0 -15px;
    }
    .title {
        h2 {
            font-weight: 300 !important;
            line-height: 40px;
            span {
                color: $theme-color;
                font-weight: 700;
            }
        }
    }
}
.bg-white {
    padding: 10px 0;
    background: $white-color !important;
    box-shadow: 0px 0px 8px 0px rgba(0,0,0,0.1);
    .main-menu {
        li {
            &.active {
                a {
                    color: $title-color !important;
                }
            }
        }
    }
}
.banner-wrapper {
    &.banner-none {
        display: none;
    }
}

.section-wrapper {
    @include breakpoint(xl) {
        margin: 0 -15px;
    }
}

// extend tag
%flex {
    display: flex;
    flex-wrap: wrap;
}
%position {
    position: absolute;
    content: "";
}
%mp-0 {
    margin: 0;
    padding: 0;
}
%pricing-bt {
    width: 100%;
    height: 6px;
    top: 0px;
    left: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}
%border {
    border: 10px solid #f1f6fa;
}
%widget-pad {
    padding: 20px 20px 30px;
}
%widget-mt {
    margin-top: 35px;
}

// text-center
.text-center {
    text-align: center;
    z-index: 1;
    position: relative;
}

// button
.btn {
    position: relative;
    color: $white-color;
    padding: 10px 30px;
    border-radius: 2px;
    text-transform: capitalize;
    font-weight: 400;
    font-size: $fs-h6;
    text-decoration: none;
    font-family: $font-family-f;
    box-shadow: 0px 1px 2px 1px rgba(0,0,0,0.1);
    @include transition($transition);
    &::before {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .3s ease;
        background-image: linear-gradient(to left, #fed643, #ff4181);
    }
    &::after {
        position: absolute;
        width: 100%;
        height: 100%;
        content: "";
        background-image: linear-gradient(to right, #fed643, #ff4181);
        top: 0;
        left: 0;
        z-index: -1;
        transition: all .3s ease;
    }
    img {
        margin-left: 24px;
        transform: translateY(6px);
    }
    span {
        transform: translateY(-1px);
        display: inline-block;
    }
    &:hover {
        color: $white-color;
        &::after {
            opacity: 0;
        }
    }
}

// scrollTop
.scrollToTop{
    position: fixed;
    bottom: -30%;
    right: 5%;
    z-index: 9;
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: $theme-color;
    text-align: center;
    border-radius: 50%;
    color: $white-color;
    transform: rotate(90deg);
    @include transition($transition);
    &:hover {
        color: $white-color;
        background: $primary-color;
    }
}

// social link list start here
.social-link-list {
    margin-bottom: 0;
    margin-left: 0;
    justify-content: center;
    @extend %flex;
    li {
        list-style: none;
        a {
            width: 40px;
            height: 40px;
            line-height: 37px;
            text-align: center;
            color: $theme-color;
            background: $white-color;
            border-radius: 50%;
            margin: 0 5px;
            @include transition($transition);
            border: 2px solid transparent;
            box-shadow: 0px 10px 30px 0px rgba(0,0,0,0.1);
        }
    }
}

// common social media
.facebook {
    background: $facebook;
    &:hover {
        background: $white-color;
        border-color: $facebook !important;
        i {
            color: $facebook !important;
        }
    }
}
.twitter {
    background: $twitter;
    border-color: $twitter !important;
    &:hover {
        background: $white-color;
        i {
            color: $twitter !important;
        }
    }
}
.twitter-sm {
    background: $twitter;
    &:hover {
        background: $white-color;
        border-color: $twitter;
        i {
            color: $twitter !important;
        }
    }
}
.google {
    background: $google;
    &:hover {
        background: $white-color;
        border-color: $google !important;
        i {
            color: $google !important;
        }
    }
}
.linkedin {
    background: $linkedin;
    &:hover {
        background: $white-color;
        border-color: $linkedin !important;
        i {
            color: $linkedin !important;
        }
    }
}
.instagram {
    background: $instagram;
    &:hover {
        background: $white-color;
        border-color: $instagram !important;
        i {
            color: $instagram !important;
        }
    }
}
.tumblr {
    background: $tumblr;
    &:hover {
        background: $white-color;
        border-color: $tumblr !important;
        i {
            color: $tumblr !important;
        }
    }
}
.behance {
    background: $behance;
    &:hover {
        background: $white-color;
        border-color: $behance !important;
        i {
            color: $behance !important;
        }
    }
}
.youtube {
    background: $youtube;
    &:hover {
        background: $white-color;
        border-color: $youtube !important;
        i {
            color: $youtube !important;
        }
    }
}

// scrollbar effect
#scrollbar::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: $white-color;
}
#scrollbar::-webkit-scrollbar {
    width: 4px;
    background-color: $white-color;
}
#scrollbar::-webkit-scrollbar-thumb {
    background-color: $theme-color;
    border-radius: 8px;
}
.box-shadow-style {
    position: relative;
    z-index: 4;
    background: $white-color;
    max-width: 100%;
    margin: auto;
    box-shadow: 0px 3px 8px 0px rgba(0,0,0,0.1);
    @include breakpoint(xl) {
        max-width: 1250px;
    }
    @include breakpoint(xxl) {
        max-width: 1400px;
    }
}

// category 
.category{
    font-size: $fs-h6;
    padding: 6px 24px;
    margin-bottom: 15px;
    color: $white-color;
    position: relative;
    display: inline-block;
    font-weight: 400;
    font-family: $font-family-f;
    background-image: linear-gradient(to right, #fed643, #ff4181);
    background-repeat: no-repeat;
    &::after {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        border-left: 0px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid $primary-color;
        bottom: -8px;
        left: 0;
    }
}

// golden title
.gold-title {
    margin: -10px 0;
    color: $white-color;
}
.cd-words-wrapper {
    display: inline-block;
    position: relative;
    text-align: left;
}
.cd-words-wrapper b {
    display: inline-block;
    position: absolute;
    white-space: nowrap;
    left: 0;
    top: 0;
    font-style: normal;
    font-weight: 700;
}
.cd-words-wrapper i {
    font-style: normal;
}
.cd-words-wrapper b.is-visible {
    position: relative;
}
.no-js .cd-words-wrapper b {
    opacity: 0;
}
.no-js .cd-words-wrapper b.is-visible {
    opacity: 1;
}
.cd-headline.clip .cd-words-wrapper {
    overflow: hidden;
    vertical-align: top;
}
.cd-headline.clip b {
    opacity: 0;
}
.cd-headline.clip b.is-visible {
    opacity: 1;
}



// animate
@keyframes animate1 {
    0% {
        -moz-transform: translate(3%,0%);
        -webkit-transform: translate(3%,0%);
        transform: translate(3%,0%);
    }
    25% {
        -moz-transform: translate(0%,3%);
        -webkit-transform: translate(0%,3%);
        transform: translate(0%,3%);
    }
    50% {
        -moz-transform: translate(3%,3%);
        -webkit-transform: translate(3%,3%);
        transform: translate(3%,3%);
    }
    75% {
        -moz-transform: translate(0%,3%);
        -webkit-transform: translate(0%,3%);
        transform: translate(0%,3%);
    }
    100% {
        -moz-transform: translate(3%,0%);
        -webkit-transform: translate(3%,0%);
        transform: translate(3%,0%);
    }
}

// particles-js
#particles-js, #particles-js2 {
    position: absolute;
    width: 100%;
    height: 100%;
    background: transparent;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}

//Custom Container
@include breakpoint(xl) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1170px !important;
    }
}